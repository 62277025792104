import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ENV, EnvVars } from '@realwear-cloud/shared/env';
import { map } from 'rxjs/operators';

@Injectable()
export class CanaryService {
  isCanary$: Observable<boolean>;

  public switchMode() {
    window.location.href = '/account/canary';
  }

  constructor(httpClient: HttpClient, @Inject(ENV) env: EnvVars) {
    if (!env.production) {
      this.isCanary$ = of(false);
      return;
    }

    this.isCanary$ = httpClient
      .get<{ canary: boolean }>(`${env.stsServer}/account/canary/check`)
      .pipe(map((res) => res.canary));
  }
}
