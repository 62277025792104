import {
  EventEmitter,
  Host,
  Inject,
  Injectable,
  InjectionToken,
  OnDestroy,
  Optional,
  Provider,
  SkipSelf
} from '@angular/core';

export const CACHE_CONFIG = new InjectionToken<CacheConfig>('CACHE_CONFIG');

/**
 * Refresher service created a refresh tree for any components.
 * It can signal a refresh of all child components from a parent, or,
 * can request a refresh of the parent components from a child
 */
@Injectable()
export class Refresher implements OnDestroy {
  static _idCounter = 0;

  /**
   * Emits when a refresh event has been triggered.
   * The value emitted is the name of the component that triggered the refresh
   */
  public readonly refreshRequested = new EventEmitter<string>();

  private readonly _id: number;
  private readonly _parent: Refresher;

  protected readonly _config: CacheConfig;

  /**
   * Unique token for this service. Used to identify the component that triggered the refresh
   */
  public readonly token: string;

  private readonly _children: Refresher[] = [];

  /**
   * Creates an instance of the Refresher provider for a child component.
   * @param componentRef Component that will host the refresher
   */
  static createForComponent<T>(componentRef: T): Provider[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const name = (componentRef as any).name;

    return [
      Refresher,
      {
        provide: CACHE_CONFIG,
        useValue: {
          componentName: name
        }
      }
    ];
  }

  /**
   * Creates an instance of the Refresher provider for a parent component.
   * @param componentRef Component that will host the refresher
   * @returns
   */
  static createForTopLevel<T>(componentRef: T): Provider[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const name = (componentRef as any).name;

    return [
      Refresher,
      {
        provide: CACHE_CONFIG,
        useValue: {
          componentName: name,
          isTopLevel: true
        }
      }
    ];
  }

  constructor(@Optional() @SkipSelf() parent: Refresher, @Host() @Inject(CACHE_CONFIG) config: CacheConfig) {
    this._parent = parent;
    this._id = ++Refresher._idCounter;
    this._config = config;

    this.token = config.componentName + ' - ' + this._id;

    if (parent != null) {
      parent.addChild(this);
    }
  }

  /**
   * Unique ID of this Refresher instance
   */
  get id() {
    return this._id;
  }

  /**
   * Immediate Parent for this Refresher instance (could be nested in a multi-level tree)
   */
  get parent() {
    return this._parent;
  }

  /**
   * INTERNAL - Emits the Refresh Requested event
   */
  onRefreshRequested(callingToken?: string) {
    callingToken = this.createCallingToken(callingToken);

    this.refreshRequested.emit(callingToken);
  }

  /**
   * Refreshes all children this Refresher instance.
   * The refreshRequested event will emit to each component
   */
  refreshChildren(callingToken?: string) {
    callingToken = this.createCallingToken(callingToken);

    this.onRefreshRequested(callingToken);

    this._children.forEach((val) => {
      val.refreshChildren(callingToken);
    });
  }

  /**
   * Requests a refresh of the parent component.
   * This will keep traveling up the stack until it reaches the root component
   */
  requestPageRefresh() {
    this._requestPageRefresh(this.createCallingToken());
  }

  protected _requestPageRefresh(callingToken?: string) {
    const shouldRefreshSelf = callingToken !== this.createCallingToken(undefined);

    callingToken = this.createCallingToken(callingToken);

    if (shouldRefreshSelf) {
      this.onRefreshRequested(callingToken);
    }

    if (!this._parent) {
      return;
    }

    if (this._config.isTopLevel) {
      // Stop here
      return;
    }

    this._parent._requestPageRefresh(callingToken);
  }

  private createCallingToken(callingToken?: string) {
    return callingToken || this.token;
  }

  /**
   * Unregisters this Refresher from the tree.
   * Will detach this instance from the parent
   * @returns
   */
  ngOnDestroy() {
    if (!this._parent) {
      return;
    }

    this._parent.removeChild(this);
  }

  protected addChild(child: Refresher) {
    this._children.push(child);
  }

  protected removeChild(child: Refresher) {
    const index = this._children.indexOf(child);

    if (index !== -1) {
      this._children.splice(index, 1);
    }
  }
}

interface CacheConfig {
  componentName: string;
  isTopLevel: boolean;
}
