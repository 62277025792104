export function titleForCategory(category?: string | null): string {
  switch (category) {
    case 'remoteassist':
      return 'Remote Assist Apps';
    case 'realwear':
      return 'Apps By RealWear';
    case 'workflow':
      return 'Workflow Apps';
    case 'training':
      return 'Training Apps';
    case 'certified':
      return 'Certified by RealWear';
    case 'inspection':
      return 'Inspection Apps';
    case 'ai':
      return 'AI Apps';
    case 'all':
      return 'All Apps';
  }

  return 'Unknown';
}
