import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subscription,
  switchMap,
  tap
} from 'rxjs';
import { AppService } from '../../data-access/services/app.service';
import { AppWithAssignment, MarketplaceApp } from '../../types/marketplace-app';
import { titleForCategory } from '../../helpers/categories';
import { FormControl } from '@angular/forms';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'realwear-cloud-category-viewer',
  templateUrl: './category-viewer.component.html',
  styleUrls: ['./category-viewer.component.scss']
})
export class CategoryViewerComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput!: ElementRef;

  readonly title$: Observable<string>;

  readonly apps$: Observable<MarketplaceApp[] | AppWithAssignment[]>;
  readonly searchControl = new FormControl('');
  faSearch = faSearch;

  hasAppsLoaded = false;
  @Input() showSearch = true;
  @Input() scrollable = false;

  @Input()
  set objectId(id: string) {
    this.objectId$.next(id);
  }

  readonly category$ = new BehaviorSubject<string>('');
  readonly objectId$ = new BehaviorSubject<string>('');

  @Input()
  set category(category: string) {
    this.category$.next(category);
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
  }

  get category(): string {
    return this.category$.value;
  }

  @Input() showHeader = true;

  readonly loading$ = new BehaviorSubject<boolean>(false);

  readonly error$ = new BehaviorSubject<boolean>(false);

  readonly state$: Observable<'error' | 'loading' | null>;

  readonly retryEvent$ = new BehaviorSubject<boolean>(true);

  private titleSubscription?: Subscription;

  constructor(private appService: AppService, private title: Title) {
    this.state$ = this.createStateObs();

    this.title$ = this.category$.pipe(map((category) => titleForCategory(category)));

    this.apps$ = combineLatest([this.category$, this.retryEvent$, this.objectId$]).pipe(
      tap(() => {
        // on assignment change of a single app, don't go to loading state for all apps
        if (!this.hasAppsLoaded) {
          this.loading$.next(true);
        }
        this.error$.next(false);
      }),
      switchMap(([category, , objectId]) => {
        return this.appService.appsForCategory(category || '').pipe(
          switchMap((appData) => {
            this.hasAppsLoaded = true;
            if (!objectId) return of(appData);

            return this.appService.addAssignmentsToApps(
              of(appData),
              this.appService.getObjectAssignments(this.objectId$)
            );
          }),
          catchError(() => {
            this.error$.next(true);
            this.hasAppsLoaded = false;
            return of<MarketplaceApp[]>([]);
          })
        );
      }),

      tap(() => this.loading$.next(false)),
      switchMap((apps) => {
        return combineLatest([of(apps), this.searchControl.valueChanges.pipe(startWith(''))]);
      }),
      map(([apps, searchTerm]) => {
        // filter on app title and author basec on search term

        if (!searchTerm) return apps;

        const searchTerms = searchTerm.toLowerCase().split(' ');

        return apps.filter((app) => {
          const title = app.title.toLowerCase();
          const author = app.author.toLowerCase();

          return searchTerms.every((term) => title.includes(term) || author.includes(term));
        });
      }),
      shareReplay(1)
    );
  }

  ngOnInit(): void {
    this.titleSubscription = this.title$.subscribe((categoryTitle) => {
      this.title.setTitle(`${categoryTitle} - RealWear App Marketplace`);
    });
  }

  ngOnDestroy(): void {
    this.titleSubscription?.unsubscribe();
  }

  private createStateObs(): Observable<'error' | 'loading' | null> {
    return combineLatest([this.loading$, this.error$]).pipe(
      map(([loading, error]) => {
        if (error) {
          return 'error';
        }

        if (loading) {
          return 'loading';
        }

        return null;
      })
    );
  }
}
