import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { map, Observable, of, OperatorFunction, shareReplay, startWith, switchMap, takeUntil } from 'rxjs';
import { AppService, FeaturedApp } from '../../data-access/services/app.service';
import { MarketplaceApp, shuffle } from '../../types/marketplace-app';
import { BaseComponent } from '@realwear-cloud/shared/ui-kit';

@Component({
  selector: 'realwear-cloud-category-featured',
  templateUrl: './category-featured.component.html',
  styleUrls: ['./category-featured.component.scss']
})
export class CategoryFeaturedComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('myvideo') myvideo?: ElementRef<HTMLVideoElement>;
  readonly appsRemoteAssistance$: Observable<MarketplaceApp[]>;
  readonly appsWorkflow$: Observable<MarketplaceApp[]>;
  readonly featuredSlots$: Observable<FeaturedApp[]>;
  readonly certifiedSlots$: Observable<MarketplaceApp[]>;

  readonly slotHero$: Observable<FeaturedApp>;
  readonly slotSmall1$: Observable<FeaturedApp>;
  readonly slotSmall2$: Observable<FeaturedApp>;

  readonly isLoading$: Observable<boolean>;

  certifiedAppCount = 0;
  certifiedApps: MarketplaceApp[] = [];
  remoteAssistApps: MarketplaceApp[] = [];
  workflowApps: MarketplaceApp[] = [];

  constructor(apps: AppService, title: Title) {
    super();
    this.appsRemoteAssistance$ = apps.getSubFeaturedApps('remoteassist', 3);
    this.appsWorkflow$ = apps.getSubFeaturedApps('workflow', 3);
    this.featuredSlots$ = apps.getFeaturedApps();
    this.certifiedSlots$ = apps.appsForCategory('certified', 6);

    this.slotHero$ = this.featuredSlots$.pipe(extractSlot('hero'));
    this.slotSmall1$ = this.featuredSlots$.pipe(extractSlot('slot1'));
    this.slotSmall2$ = this.featuredSlots$.pipe(extractSlot('slot2'));

    this.isLoading$ = this.appsWorkflow$.pipe(
      switchMap(() => of(false)),
      startWith(true),
      shareReplay(1)
    );

    title.setTitle('Featured Apps - RealWear App Marketplace');

    this.loadCertifiedApps();
  }
  ngAfterViewInit(): void {
    if (this.myvideo) this.myvideo.nativeElement.muted = true;
  }

  fetchAppUrl(app: MarketplaceApp) {
    return '/app/' + app.id;
  }

  loadCertifiedApps(): void {
    this.certifiedSlots$.pipe(takeUntil(this.destroyed$)).subscribe((result) => {
      shuffle(result);
      this.certifiedAppCount = result.length;
      this.certifiedApps = result;
    });
  }
}

function extractSlot(slotName: string): OperatorFunction<FeaturedApp[], FeaturedApp> {
  const createBlankSlot: () => FeaturedApp = () => ({ slot: slotName, title: 'Unknown', imageUrl: '', href: '' });

  return (source) => source.pipe(map((j) => j.find((k) => k.slot === slotName) || createBlankSlot()));
}
