import { NgModule } from '@angular/core';
import { StringJoinPipe } from './pipes/string-join.pipe';
import { ObjectIdTimestampPipe } from './pipes/object-id-timestamp.pipe';
import { ReverseArrayPipe } from './pipes/reverse-array.pipe';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { AppPackageNameTranslation } from './pipes/app-package-name-translations.pipe';
import { DecodeDeviceModelFromFirmwareVersionStringPipe } from './pipes/decode-device-model-from-firmware-version-string.pipe';
import { FirmwareVersionTranslationPipe } from './pipes/firmware-version-translation.pipe';
import { StripPipe } from './pipes/strip.pipe';
import { UserFirmwarePipe } from './pipes/user-firmware.pipe';
import { BytesPipe } from './pipes/bytes.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

const pipes = [
  StringJoinPipe,
  ObjectIdTimestampPipe,
  ReverseArrayPipe,
  RelativeDatePipe,
  AppPackageNameTranslation,
  DecodeDeviceModelFromFirmwareVersionStringPipe,
  FirmwareVersionTranslationPipe,
  StripPipe,
  UserFirmwarePipe,
  BytesPipe,
  SanitizeHtmlPipe
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes]
})
export class SharedUtilsPipesModule {}
