import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'realwear-cloud-release-notes-dialog',
  templateUrl: './release-notes-dialog.component.html',
  styleUrls: ['./release-notes-dialog.component.scss']
})
export class ReleaseNotesDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public releaseNotes: string) {}
}

export function openReleaseNotesDialog(
  matDialog: MatDialog,
  releaseNotes: string
): MatDialogRef<ReleaseNotesDialogComponent> {
  const ret = matDialog.open(ReleaseNotesDialogComponent, {
    data: releaseNotes,
    width: '800px'
  });

  return ret;
}
