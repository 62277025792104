import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppLinkComponent } from './components/app-grid/app-link/app-link.component';
import { AppGridComponent } from './components/app-grid/app-grid.component';
import { CategoryViewerComponent } from './components/category-viewer/category-viewer.component';
import { AppDetailComponent } from './components/app-detail/app-detail.component';
import { ReleaseNotesDialogComponent } from './dialogs/release-notes-dialog/release-notes-dialog.component';
import { MarketplaceButtonComponent } from './components/marketplace-button/marketplace-button.component';
import { AppVideoComponent } from './components/app-detail/app-video/app-video-component';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { CategoryNamePipe } from './pipes/category-name.pipe';
import { RemoveHttpPipe } from './pipes/remove-http.pipe';
import { NgModule } from '@angular/core';
import { AppService } from './data-access/services/app.service';
import { CheckAppExistsGuard } from './guards/app-exists.guard';
import { CategoryFeaturedComponent } from './components/category-featured/category-featured.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MarketplaceStore } from './data-access/store/marketplace.store';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InstallAppDialogComponent } from './dialogs/install-app-dialog/install-app-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { ComponentsModule } from '@realwear-cloud/shared/ui-kit';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    RouterModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    OverlayModule,
    FontAwesomeModule,
    ReactiveFormsModule,

    MatRippleModule,
    MatTooltipModule,
    MatCardModule,
    ComponentsModule
  ],
  declarations: [
    AppLinkComponent,
    AppGridComponent,
    CategoryViewerComponent,
    CategoryFeaturedComponent,
    AppDetailComponent,
    MarketplaceButtonComponent,
    AppVideoComponent,
    ReleaseNotesDialogComponent,
    InstallAppDialogComponent,
    BackgroundImageDirective,
    CategoryNamePipe,
    RemoveHttpPipe
  ],
  exports: [
    AppGridComponent,
    CategoryViewerComponent,
    AppDetailComponent,
    MarketplaceButtonComponent,
    CategoryNamePipe,
    CategoryFeaturedComponent
  ],
  providers: [AppService, CheckAppExistsGuard, MarketplaceStore]
})
export class MarketplaceSharedModule {}
