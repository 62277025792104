import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { NotificationContainerComponent } from './notification-container/notification-container.component';
import { PopupNotificationConfig } from './popup-notification-config';
import { PopupNotificationRef } from './popup-notification-ref';

@Injectable()
export class PopupNotificationManager {
  readonly overlayRef: OverlayRef;

  portal?: ComponentPortal<NotificationContainerComponent>;

  private readonly _activeNotifications: PopupNotificationRef[] = [];

  constructor(overlay: Overlay) {
    this.overlayRef = overlay.create({
      positionStrategy: overlay.position().global().right('5px').bottom('5px')
    });
  }

  get activeNotifications(): Readonly<PopupNotificationRef[]> {
    return this._activeNotifications;
  }

  public addNotification(config: PopupNotificationConfig | string): PopupNotificationRef {
    let incomingConfig: PopupNotificationConfig;

    // Convert a string into a nice Info config
    if (typeof config === 'string') {
      incomingConfig = {
        state: 'info',
        text: config
      } as PopupNotificationConfig;
    } else {
      incomingConfig = config;
    }

    if (!incomingConfig.state) {
      incomingConfig.state = 'info';
    }

    // If the unique key is set, remove any others with the same key
    if (incomingConfig.uniqueKey) {
      this._activeNotifications
        .filter((j) => j.config.uniqueKey === incomingConfig.uniqueKey)
        .forEach((j) => {
          this.removeNotification(j);
        });
    }

    // Create the ref
    const notificationRef = new PopupNotificationRef(Math.random().toString(), incomingConfig, (n) =>
      this.removeNotification(n)
    );

    this._activeNotifications.push(notificationRef);

    this.ensureCreated();
    this.ensureSize();

    return notificationRef;
  }

  public removeNotification(notification: PopupNotificationRef) {
    if (!notification) {
      return;
    }

    const index = this._activeNotifications.indexOf(notification);

    if (index === -1) {
      return;
    }

    this._activeNotifications.splice(index, 1);

    this.ensureSize();
  }

  private ensureCreated() {
    if (!this.portal) {
      this.portal = new ComponentPortal(NotificationContainerComponent);
    }

    if (!this.portal.isAttached) {
      const ref = this.portal.attach(this.overlayRef);

      ref.instance.attachments = this._activeNotifications;
    }
  }

  private ensureSize() {
    let height = this._activeNotifications.length * 50;

    if (height === 0) {
      if (this.portal?.isAttached) {
        this.portal?.detach();
      }

      return;
    }

    // Add 5px padding between each item
    height += (this.activeNotifications.length - 1) * 5;

    this.overlayRef.updateSize({
      width: '350px',
      height: height + 'px'
    });
  }
}
