import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import { firstValueFrom } from 'rxjs';
import { MarketplaceAuth } from '../auth/marketplace-auth.service';

@Injectable()
export class SilentAuthGuard implements CanActivate {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(private authService: MarketplaceAuth, @Inject(PLATFORM_ID) private platformId: Object) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    if (!isPlatformBrowser(this.platformId)) {
      return true;
    }

    const hasSilentlyAuthed = sessionStorage.getItem('has_silent_auth');

    if (hasSilentlyAuthed) {
      return true;
    }

    const isAuthenticated = await firstValueFrom(this.authService.isAuthenticated$);

    datadogRum.setUserProperty('isAuthenticated', isAuthenticated);

    if (isAuthenticated) {
      return true;
    }

    const appId = route.paramMap.get('id');

    if (!appId) {
      return true;
    }

    sessionStorage.setItem('has_silent_auth', 'true');

    this.authService.silentAuthorizeForAppId(appId);

    return false;
  }
}
