/* eslint-disable no-console */
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RwcConsoleService {
  static _rwcCloudAssistanceStarted = false;
  static logBootStrapError(error: string) {
    console.error("Can't bootstrap application: " + error);
  }

  constructor(@Inject('IS_PRODUCTION') private isProduction: boolean) {}

  logObs<T>(obs: Observable<T>, log: string): Observable<T> {
    return obs.pipe(
      tap(() => {
        if (!this.isProduction) console.log('Emitting: ' + log);
      })
    );
  }

  log(message: unknown) {
    if (!this.isProduction) console.log(message);
  }

  logCloudAssistance(message: unknown) {
    if (!RwcConsoleService._rwcCloudAssistanceStarted) {
      console.log('Realwear Cloud Assistance');
      RwcConsoleService._rwcCloudAssistanceStarted = true;
    }
    console.log(message);
  }
}
