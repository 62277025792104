<div class="mat-card notification mat-elevation-z10" *ngFor="let attach of attachments">
  <div class="begin">
    <mat-spinner *ngIf="attach.config.state === 'loading'" diameter="25"></mat-spinner>
    <fa-icon size="xl" *ngIf="attach.config.state === 'info'" [icon]="faInfo"></fa-icon>
    <fa-icon size="xl" *ngIf="attach.config.state === 'success'" [icon]="faCheck"></fa-icon>
    <fa-icon size="xl" *ngIf="attach.config.state === 'failed'" [icon]="faWarning"></fa-icon>
  </div>

  <div
    class="text"
    [attr.data-testid]="
      'notification-text' +
      (attach.config.uniqueKey ? '-' + attach.config.uniqueKey.toLowerCase() : '') +
      (attach.config.state ? '-' + attach.config.state : '')
    ">
    {{ attach.config.text }}
  </div>

  <button class="undo" *ngIf="attach.config.undoFunction" (click)="onUndo(attach)" mat-flat-button>Undo</button>

  <button mat-icon-button *ngIf="attach.config.state !== 'loading'" (click)="onRemove(attach)">
    <fa-icon size="xl" [icon]="faClose"></fa-icon>
  </button>

  <ng-container>
    <mat-progress-bar
      *ngIf="attach.config.state !== 'loading' && !attach.infinite"
      [color]="attach.config.state === 'failed' ? 'warn' : 'primary'"
      [value]="attach.countdown$ | async"></mat-progress-bar>
  </ng-container>
</div>
