import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, HostBinding } from '@angular/core';
import { AppWithAssignment, MarketplaceApp } from '../../types/marketplace-app';
import { MarketplaceStore } from '../../data-access/store/marketplace.store';

@Component({
  selector: 'realwear-cloud-app-grid',
  templateUrl: './app-grid.component.html',
  styleUrls: ['./app-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppGridComponent {
  @Input() apps: MarketplaceApp[] | AppWithAssignment[] = [];

  @Input() showInstallButtons = false;

  @Input() state?: 'loading' | 'error' | null;

  @Input() noAppsMessage = '';
  @Input() isEnterprise = false;

  @Input() scrollable = false;
  @HostBinding('class.scrollable') get scr() {
    return this.scrollable;
  }

  @Output() refreshRequested = new EventEmitter();

  constructor(private store: MarketplaceStore) {}

  get isShowingApps() {
    return this.apps?.length && !this.state;
  }

  get hasNoApps() {
    return !this.apps?.length && !this.state;
  }

  isAppWithAssignment(app: MarketplaceApp | AppWithAssignment): app is AppWithAssignment {
    return 'installed' in app;
  }

  onAppDetailRequested(appId: string) {
    this.store.updateState({ appDetailRequested: appId });
  }
}
