<h2 mat-dialog-title>Install {{ appName$ | async }}</h2>

<mat-dialog-content>
  <p class="instruction">
    Select the groups or devices where you wish to install this app.
    <span *ngIf="currentMode === 'Devices' && isInternal"
      >If you're assigning apps to more than 100 devices please use Device Groups</span
    >
  </p>

  <div class="side">
    <button
      data-testid="groups"
      mat-flat-button
      [class.selected]="currentMode === 'Groups'"
      (click)="changeMode('Groups')">
      Groups
    </button>
    <button
      data-testid="devices"
      mat-flat-button
      [class.selected]="currentMode === 'Devices'"
      (click)="changeMode('Devices')">
      Devices
    </button>
  </div>

  <div class="divider"></div>

  <div class="table groups" *ngIf="currentMode === 'Groups'">
    <div class="table__header">
      <mat-checkbox
        [disabled]="!hasGroups"
        data-testid="groups-select-all"
        [checked]="isFullyChecked"
        [indeterminate]="isPartiallySelected"
        (change)="toggleCheckAll($event)"></mat-checkbox>
      <span>Group</span>
      <span>Status</span>
    </div>
    <div class="table__row" *ngFor="let group of groups$ | async; let index = index">
      <mat-checkbox
        [disabled]="fetchAssigned(group)"
        [checked]="fetchAssigned(group) || fetchChecked(group)"
        (change)="toggleCheckedDevice(group, $event.checked)"
        [attr.data-testid]="'groups-row-' + (index + 1) + '-checkbox'">
      </mat-checkbox>
      <div class="table__row__cell group" data-dd-privacy="mask">
        {{ group.name }}
      </div>
      <div class="table__row__cell status">
        {{ fetchAssigned(group) ? 'Installed' : '' }}
      </div>
    </div>

    <div class="table__row empty" *ngIf="!hasGroups">
      <realwear-cloud-nothing-here></realwear-cloud-nothing-here>

      <p>
        <fa-icon [icon]="circleInfoIcon"></fa-icon>
        <span>There are no groups added to your workspace</span>
      </p>
    </div>
  </div>

  <ng-container *ngIf="currentMode === 'Devices'">
    <div class="table devices" *ngIf="(reachedDeviceLimit$ | async) !== true">
      <div class="table__header">
        <mat-checkbox
          [disabled]="!hasDevices"
          data-testid="devices-select-all"
          [checked]="isFullyChecked"
          [indeterminate]="isPartiallySelected"
          (change)="toggleCheckAll($event)"></mat-checkbox>
        <span>Device</span>
        <span>Status</span>
      </div>
      <div class="table__row" *ngFor="let device of devices$ | async; let index = index">
        <mat-checkbox
          [disabled]="fetchAssigned(device)"
          [checked]="fetchAssigned(device) || fetchChecked(device)"
          (change)="toggleCheckedDevice(device, $event.checked)"
          [attr.data-testid]="'devices-row-' + (index + 1) + '-checkbox'">
        </mat-checkbox>
        <div class="table__row__cell device" data-dd-privacy="mask">
          <span>{{ device.name }}</span>
          <span>{{ device.serialNumber }}</span>
        </div>
        <div class="table__row__cell status" [attr.data-testid]="'devices-row-' + (index + 1) + '-status'">
          {{ fetchAssigned(device) ? 'Installed' : '' }}
        </div>
      </div>

      <div class="table__row empty" *ngIf="!hasDevices">
        <realwear-cloud-nothing-here></realwear-cloud-nothing-here>

        <p>
          <fa-icon [icon]="circleInfoIcon"></fa-icon>
          <span>There are no devices added to your workspace</span>
        </p>
      </div>
    </div>

    <div class="maxdevicewarning" *ngIf="!!(reachedDeviceLimit$ | async)">
      <fa-icon [icon]="circleInfoIcon"></fa-icon>
      <p *ngIf="isInternal">
        To assign applications to more than 50 devices, please add the device to a group first. To assign to individual
        devices, visit the Device page.
      </p>
      <p *ngIf="!isInternal">
        Sorry, this option is not available to workspaces with more than 50 devices.<br />To install an application on
        individual devices, please use <strong>RealWear Cloud</strong>
      </p>
    </div>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <realwear-cloud-marketplace-button
    data-testid="dialog-install"
    [icon]="installIcon"
    value="Install {{ appName$ | async }}"
    [disabled]="!canInstall"
    (click)="onInstallApp()"></realwear-cloud-marketplace-button>
</mat-dialog-actions>
