import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { CanaryService } from './canary.service';
import { UserData } from './models/user-data.interface';

@Injectable()
export class AuthService {
  readonly userData$: Observable<UserData>;
  readonly isAuthenticated$: Observable<boolean>;
  readonly isSuperAdmin$: Observable<boolean>;
  readonly isImpersonatingWorkspace$: Observable<boolean>;
  readonly isInAdminWorkspace$: Observable<boolean>;
  readonly isOwner$: Observable<boolean>;
  readonly isOperator$: Observable<boolean>;
  readonly isCanary$: Observable<boolean>;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    router: Router,
    events: PublicEventsService,
    canaryService: CanaryService
  ) {
    this.userData$ = oidcSecurityService.userData$;
    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
    this.isSuperAdmin$ = this.userData$.pipe(map((userData) => userData?.role === 'superadmin'));
    this.isOperator$ = this.userData$.pipe(map((userData) => userData?.role === 'operator'));
    this.isOwner$ = this.userData$.pipe(map((userData) => userData?.role === 'owner'));
    this.isImpersonatingWorkspace$ = this.userData$.pipe(map((userData) => Boolean(userData?.is_impersonating)));

    // Allow Impersonation for SuperAdmin
    this.isInAdminWorkspace$ = this.isSuperAdmin$.pipe(
      map((isSuperAdmin) => (isSuperAdmin && this.hasSetWorkspace) || !isSuperAdmin)
    );

    this.isCanary$ = this.isAuthenticated$.pipe(
      filter((isAuthenticated) => isAuthenticated),
      switchMap(() => canaryService.isCanary$)
    );

    oidcSecurityService.checkAuth().subscribe((auth: boolean) => {
      if (!auth) {
        this.authorize();
        sessionStorage.setItem('routeback', window.location.href.substring(document.baseURI.length));
      }
    });

    events.registerForEvents().subscribe((i) => {
      if (i.type !== EventTypes.NewAuthorizationResult) {
        return;
      }

      const routeBack = sessionStorage.getItem('routeback') || '/';
      sessionStorage.removeItem('routeback');
      router.navigateByUrl(routeBack);
    });

    oidcSecurityService.checkSessionChanged$.pipe(filter((i) => !!i)).subscribe(() => this.authorize());
  }

  authorize(): void {
    this.oidcSecurityService.authorize();
  }

  logoff(): void {
    this.oidcSecurityService.logoff();
  }

  forceRefreshSession() {
    return this.oidcSecurityService.forceRefreshSession();
  }

  get clientId() {
    return this.oidcSecurityService.configuration.configuration.clientId;
  }

  stopImpersonating() {
    sessionStorage.removeItem('workspace');
    window.location.reload();
  }

  get hasSetWorkspace(): boolean {
    const workspaceSetting = sessionStorage.getItem('workspace');
    return !!workspaceSetting?.length;
  }
}
