import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryName'
})
export class CategoryNamePipe implements PipeTransform {
  transform(value: unknown): unknown {
    switch (value) {
      case 'remoteassist':
        return 'Remote Assist';
      case 'realwear':
        return 'By RealWear';
      case 'featured':
        return 'Featured';
      case 'workflow':
        return 'Workflow';
      case 'inspection':
        return 'Inspection';
      case 'training':
        return 'Training';
      case 'ai':
        return 'AI Apps';
      case 'all':
        return 'All Apps';
    }

    return value;
  }
}
