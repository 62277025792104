import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'realwear-cloud-app-video',
  templateUrl: './app-video-component.html',
  styleUrls: ['./app-video-component.scss']
})
export class AppVideoComponent {
  @Input() set videoUrl(url: string | null) {
    if (url) {
      this.safeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  }

  safeVideoUrl: SafeResourceUrl | null = null;

  constructor(private sanitizer: DomSanitizer) {}
}
