import { APP_INITIALIZER, InjectionToken, Injector, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { AuthService } from './auth.service';
import { LoginGuard } from './login.guard';
import { TokenInterceptor } from '@realwear-cloud/shared-feature-auth';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { AppRoutingModule } from './app-routing.module';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AppService, MarketplaceAuth, SilentAuthGuard } from '@realwear-cloud/app-marketplace/shared';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule.forRoot(),
    BrowserTransferStateModule
  ],
  providers: [
    AuthService,
    LoginGuard,
    SilentAuthGuard,
    AppService,
    {
      provide: MarketplaceAuth,
      useExisting: AuthService
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [OidcConfigService, DOCUMENT, PLATFORM_ID, AuthService],
      useFactory:
        (
          oidcConfigService: OidcConfigService,
          document: Document,
          platformId: InjectionToken<string>,
          authService: AuthService
        ) =>
        () => {
          if (!isPlatformBrowser(platformId)) {
            // Do nothing as we're not running in the browser
            return;
          }

          // Initialise Datadog
          initialiseDatadog();

          datadogRum.startSessionReplayRecording();

          oidcConfigService.withConfig({
            clientId: 'marketplace',
            stsServer: environment.stsServer,
            redirectUrl: document.baseURI,
            scope: 'openid profile offline_access policies:readwrite devices:read appcatalog:read',
            responseType: 'code',
            silentRenew: true,
            autoUserinfo: true,
            renewUserInfoAfterTokenRenew: true,
            renewTimeBeforeTokenExpiresInSeconds: 30,
            useRefreshToken: true,
            triggerAuthorizationResultEvent: true
          });

          authService.onInit();
        }
    },
    TokenInterceptor,
    {
      provide: 'API_URL',
      useValue: environment.apiBase
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      deps: [OidcSecurityService, Injector],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

function initialiseDatadog() {
  const enableDatadog = !sessionStorage.getItem('isE2E');

  if (!enableDatadog) {
    return;
  }

  datadogRum.init({
    applicationId: 'b5c6819e-880b-494e-ae4d-d4ef1feff1ef',
    clientToken: 'pub69f8cb88579ba2abd2a69e4b9af78c7b',
    site: 'datadoghq.com',
    service: 'app-marketplace',
    env: environment.production ? 'production' : 'development',
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
}
