export function retrievePackageName(value: string) {
  return (
    translations.find((p) => {
      return p.value === value.trim();
    })?.translation || value
  );
}

export const translations = [
  // These are for testing purposes
  // { translation: '02 Apk Extractor', value: 'com.ext.ui' },
  // { translation: '03 FileBrowser_3.4.3-104 GA', value: 'com.realwear.filebrowser' },
  // { translation: '04apkpure prerelease', value: 'com.apkpure.aegon' },
  // { translation: '05 Al Jazeera$', value: 'net.aljazeera.english' },

  //Fixed versions for clarity
  { translation: 'Apk Extractor', value: 'com.ext.ui' },
  { translation: 'My Files', value: 'com.realwear.filebrowser' },
  { translation: 'apk Pure PreRelease', value: 'com.apkpure.aegon' },
  { translation: 'Al Jazeera English', value: 'net.aljazeera.english' },

  // App Metrics excluded via backend
  { translation: 'Get Connected Beta', value: 'com.realwear.launcher' },
  { translation: 'iQagent', value: 'iqagent.android.realwearclient' },
  { translation: 'iQagent Forms', value: 'iqagent.android.realwear.forms' },
  { translation: 'Test', value: 'com.realwear.hmt1developerexamples' },
  { translation: 'About', value: 'com.about.realwear.aboutdevice' },
  { translation: 'RWA', value: 'com.imdex.rwa' },
  { translation: 'WearHF', value: 'com.realwear.wearhf' },
  { translation: 'Configuration', value: 'com.realwear.configuration' },
  { translation: 'Language Selector', value: 'com.realwear.languageselector' },
  { translation: 'Dutch Phonetics', value: 'com.realwear.dutchphoneticstest' },
  { translation: 'Settings', value: 'com.android.settings' },
  { translation: 'Android', value: 'com.android.systemui' },

  // Other Apps
  { translation: 'RealWear Connect', value: 'xyz.appinstall.rwconnect' },
  { translation: 'PackageInstaller', value: 'com.android.packageinstaller' },
  { translation: 'Android Permissions', value: 'com.android.permissioncontroller' },
  { translation: 'TensorFlow Lite', value: 'org.tensorflow.lite.examples.objectdetection' },
  { translation: 'P&C OrderPick', value: 'io.quodix.pnc' },
  { translation: 'RealWear Update', value: 'com.realwear.fota' },
  { translation: 'Web App Viewer', value: 'com.realwear.pageviewer' },
  { translation: 'Acty Glasses', value: 'com.acty.client.glasses' },
  { translation: 'Atheer AirSuite', value: 'com.atheer.airflow' },
  { translation: 'Atheer Lens', value: 'com.atheer.lens' },
  { translation: 'Augmentir', value: 'com.augmentir.realwear' },
  { translation: 'Circuit', value: 'com.unify.circuit.realwear' },
  { translation: 'Demo Files', value: 'samplefiles.realwear.com.samplefiles' },
  { translation: 'digiTech', value: 'com.diginonymous.digitech.realwear' },
  {
    translation: 'Dynamics FS 365 Demo',
    value: 'com.ttpsc.com.ttpsc.dynamics365fieldService'
  },
  { translation: 'EBT Body Heat Beta 2', value: 'com.realwear.ebtcamera' },
  { translation: 'Eye4Task', value: 'eu.headapp.eye4task.realwear' },
  { translation: 'FieldBit', value: 'com.fieldbit.updater' },
  { translation: 'Fusion Remote', value: 'com.jujo.fusionremote' },
  { translation: 'Help Lightning', value: 'com.vipaar.lime' },
  { translation: 'Inspect AR', value: 'com.netcetera.inspectar.realwear' },
  { translation: 'JourneyApps', value: 'com.embarkmobile.androidapp' },
  { translation: 'JourneyApps Demo App', value: 'xyz.appinstall.journeydemo' },
  { translation: 'LiveCast', value: 'com.livecast.mobile' },
  { translation: 'Microsoft Teams', value: 'com.microsoft.teams' },
  { translation: 'Mobile HMI', value: 'com.iconics.AndroidAppHub' },
  { translation: 'Movilizer', value: 'com.movilizer.client.android.app.demo' },
  { translation: 'nsFlow', value: 'com.nsFlow.nsRealWearFlow' },
  { translation: 'oculavis SHARE', value: 'de.oculavis.share.glass' },
  { translation: 'Onsight Connect', value: 'com.librestream.onsight.connect' },
  { translation: 'Onsight Flow', value: 'com.librestream.onsight.flow' },
  {
    translation: 'POINTR Easy Remote Support',
    value: 'com.DeltaCygniLabs.Pointr'
  },
  {
    translation: 'Provisur VTECH™ Interactive Tech Support',
    value: 'com.librestream.provisur.vtech'
  },
  {
    translation: 'SimplyVideo for RealWear',
    value: 'com.simplyvideo.realwear'
  },
  { translation: 'Skylight (Legacy)', value: 'io.upskill.skylight' },
  {
    translation: 'Skylight (Next-Gen Beta)',
    value: 'io.upskill.skylight.smartglasses'
  },
  { translation: 'Sodalis', value: 'de.beeware.sodalis.mobile.droid' },
  { translation: 'TeamViewer Frontline', value: 'de.ubimax.frontline.client' },
  { translation: 'TeamViewer Assist AR', value: 'com.teamviewer.pilot.lite' },
  { translation: 'Telepresenz', value: 'app.care4d' },
  { translation: 'Thermal Camera', value: 'com.realwear.thermalcamera' },
  { translation: 'Video Assist', value: 'com.honeywell.si2w.proshot' },
  {
    translation: 'Vieaura Remote Collaboration',
    value: 'com.vieaura.remoteassist'
  },
  { translation: 'ViiBE', value: 'co.viibe.app' },
  { translation: 'VisualSpection', value: 'ca.mhi.inspection' },
  { translation: 'Vital', value: 'enterprises.vital.synergy' },
  { translation: 'HPE VRG', value: 'com.hpe.myroom.vrg2' },
  {
    translation: 'VSight Remote : AR Help',
    value: 'com.hangaarlab.vsight.remoteapp'
  },
  { translation: 'Vuforia Capture', value: 'com.ptc.vuforia_capture' },
  { translation: 'Vuforia Chalk', value: 'com.vuforia.Chalk.hmd' },
  { translation: 'Vuforia View', value: 'com.ptc.vuforiaview' },
  { translation: 'WorkfloPlus', value: 'com.intoware.workfloplus' },
  { translation: 'Workview', value: 'com.augmentalis' },
  { translation: 'xInspect', value: 'de.ubimax.xinspect' },
  {
    translation: 'XM Reality Remote Guidance',
    value: 'com.xmreality.xmandroid'
  },
  { translation: 'XOi Vision', value: 'com.visionhmt' },
  { translation: 'Xpert System', value: 'com.softfoundry.esfp' },
  {
    translation: 'Honeywell Forge Worker Assist',
    value: 'com.honeywell.hci.hmt.workerassist'
  },
  { translation: 'Mx Accelerator', value: 'com.klattworks.base.tp' },
  { translation: 'Zoom', value: 'us.zoom.videomeetings' },
  { translation: 'APowerMirror', value: 'com.apowersoft.mirror' },
  { translation: 'Resco Inspections', value: 'net.resco.realwear' },
  { translation: 'GlarAssist', value: 'com.glartek.remoteassistance' },
  {
    translation: 'Schenck Process CONiQ Assist',
    value: 'com.librestream.schenck.ca'
  },
  {
    translation: 'ASK Chemicals ASKNow',
    value: 'com.librestream.ask.chemicals'
  },
  { translation: 'Elixar', value: 'com.bondilabs.elixar.prod.intl' },
  { translation: 'Brochesia', value: 'com.transtecservices.bviewtranstec' },
  { translation: 'RemoteHero', value: 'eu.codelab.remotehero' },
  { translation: 'Vieaura Apps', value: 'com.vieaura.apps' },
  { translation: 'alexa', value: 'com.realwear.alexa' },
  { translation: 'Amazon', value: 'com.amazon.avod.thirdpartyclient' },
  { translation: 'KakaoTalk', value: 'com.kakao.talk' },
  {
    translation: 'Intune Company Portal',
    value: 'com.microsoft.windowsintune.companyportal'
  },
  { translation: 'Google Chrome', value: 'com.android.chrome' },
  { translation: 'Dolphin Browser', value: 'mobi.mgeek.TunnyBrowser' },
  {
    translation: 'RealWear My Camera Accessory',
    value: 'com.realwear.borescope'
  },
  { translation: 'Cisco Expert on Demand', value: 'cisco.com.expertondemand' },
  { translation: 'xSetup', value: 'de.ubimax.xsetup' },
  { translation: 'Remote Support', value: 'com.wideum.remoteeye' },
  { translation: 'One Drive', value: 'com.microsoft.skydrive' },
  { translation: 'Google Services', value: 'com.google.android.gms' },
  { translation: 'Demo app', value: 'org.zwanoo.android.speedtest' },
  { translation: 'iAuditor', value: 'com.safetyculture.iauditor' },
  { translation: 'JodaPro Healthcare', value: 'com.cisco.sdk_android' },
  { translation: 'MDMagent131', value: 'net.agreeyamobility.kratos.service' },
  { translation: 'Webapps-Launcher', value: 'com.realwear.weblauncher' },
  { translation: 'Blitzz', value: 'blitzz.co.blitzz' },
  { translation: 'Audio Recorder', value: 'com.realwear.audiorecorder' },
  { translation: 'Formos agent', value: 'com.realwear.deviceagent' },
  { translation: 'WWE123', value: 'com.nway.wweundisputed' },
  { translation: 'Вагиф Сәмәдоғлу', value: 'bbc.mobile.news.ww' },
  { translation: 'Notebook', value: 'com.realwear.notebook' },
  { translation: 'Bluetooth Phone Dialler App', value: 'com.realwear.phone' },
  { translation: 'Argus RS', value: 'nl.vrowl.argus.realwear' },
  { translation: 'Sphere', value: 'com.holoone.sphererealwear' },
  { translation: 'Deephow Capture', value: 'com.deephow' },
  {
    translation: 'Asset Manager',
    value: 'com.sap.mobile.apps.assetmanager.release'
  },
  { translation: 'Excel', value: 'com.microsoft.office.excel' },
  { translation: 'Outlook', value: 'com.microsoft.office.outlook' },
  { translation: 'Edge', value: 'com.microsoft.emmx' },
  { translation: 'Speech Tester', value: 'com.realwear.speech_tester' },
  { translation: 'MyCamera Intent', value: 'com.realwear.camera2' },
  { translation: 'Skylight', value: 'skylight.mvvm.shared' },
  {
    translation: 'Data Dashboard',
    value: 'com.ni.labview.SharedVariableViewer'
  },
  {
    translation: 'WorkfloPlus v2',
    value: 'com.intoware.workfloplus2'
  },
  { translation: 'Coresight', value: 'coresight.realwear.com.demo' },
  { translation: 'FLIR One', value: 'com.flir.flironeexampleapplication' },
  { translation: 'MicFix', value: 'com.realwear.micfix' },
  { translation: 'Ubimax xAssist', value: 'de.ubimax.xassist' },
  {
    translation: 'IntelaTrac',
    value: 'com.schneiderelectric.intelatrac.mobile'
  },
  { translation: 'TTS Module', value: 'com.realwear.tts' },
  { translation: 'Mirroring360', value: 'com.splashtop.m360.tx' },
  { translation: '10D Bluedot', value: 'net.tendegrees.sampleappextend' },
  { translation: 'Fieldbit', value: 'com.fieldbit.glasses' },
  {
    translation: 'Shopping Cart',
    value: 'com.realwear.bluetoothbarcodereaderexample'
  },
  { translation: 'CBN Network', value: 'realwear.com.cbnnetwork' },
  { translation: "Athlete's Playbook", value: 'realwear.com.athletesplaybook' },
  { translation: 'Phone Dialer', value: 'hmt.realwear.phone' },
  { translation: 'Netflix', value: 'com.netflix.mediaclient' },
  { translation: 'Spotify', value: 'com.spotify.music' },
  { translation: 'Cisco Spark', value: 'realwear.com.remotementor' },
  { translation: 'Thales', value: 'com.thales.arproject' },
  { translation: 'Essert', value: 'com.essert.augmentedautomation' },
  { translation: 'My Camera', value: 'com.realwear.camera' },
  { translation: 'Flir One Gen 3', value: 'com.realwear.flironeapp' },
  { translation: 'Honeywell Experion', value: 'com.honeywell.operate' },
  { translation: 'WebEx Teams for HMT', value: 'realwear.com.remotementor2' },
  { translation: 'Box', value: 'com.box.android' },
  { translation: 'AutoScroll', value: 'com.michael.fournier.autoscroll' },
  {
    translation: 'Teamviewer Host for HMT',
    value: 'com.teamviewer.host.market'
  },
  {
    translation: 'Barcode scanner 1D',
    value: 'com.google.zxing.client.android'
  },
  { translation: 'Movilizer', value: 'com.movilizer.client.android.app.qa' },
  { translation: 'Tetrominos', value: 'com.realwear.tetrominogame' },
  { translation: 'Vuforia Chalk', value: 'com.vuforia.Chalk' },
  { translation: 'Signal Third-Eye', value: 'by.axonim.te_phone' },
  {
    translation: 'MobileIron provisioner',
    value: 'com.mobileiron.client.android.nfcprovisioner'
  },
  { translation: 'Sightcall Visual Support', value: 'com.sightcall.sightcall' },
  { translation: 'ScopeAR', value: 'com.scopear.RemoteAR' },
  { translation: 'Dropbox', value: 'com.dropbox.android' },
  { translation: 'Occulavis', value: 'de.oculavis.share.oculavisshare' },
  { translation: 'Sales Demo App', value: 'com.user.luncher' },
  { translation: 'VideoRecorder', value: 'com.force.boostcamera' },
  { translation: 'Via Browser', value: 'mark.via.gp' },
  { translation: 'RealWear Tagging Service', value: 'com.realwear.tagservice' },
  {
    translation: 'Teamviewer QuickSupport',
    value: 'com.teamviewer.quicksupport.market'
  },
  { translation: 'Essert - Augmented Support', value: 'com.essert.as2' },
  {
    translation: 'Space1 Maintenance',
    value: 'it.space1.maintenance.realware.demo'
  },
  {
    translation: 'Space1 Virtual Collaboration',
    value: 'it.space1.virtualcollaboration.demo'
  },
  { translation: 'HMT Design Prototype', value: 'com.RealWear.HMT' },
  { translation: 'Site Survey', value: 'com.realwear.sitesurvey' },
  { translation: 'androitsgpstest', value: 'com.androits.gps.test.pro' },
  { translation: 'Pilot Glasses', value: 'com.teamiewer.pilot.glasses' },
  { translation: 'Miracast WIFI display', value: 'com.phongphan.miracast' },
  { translation: 'AirWatch Agent', value: 'com.airwatch.androidagent' },
  {
    translation: 'RealWearOEMService',
    value: 'com.airwatch.admin.awoem.realwear'
  },
  { translation: 'Kanepi', value: 'com.kanepi.kanepi' },
  {
    translation: 'Resource Installer',
    value: 'com.realwear.resourceinstaller'
  },
  { translation: 'Big Tin Can', value: 'com.bigtincan.mobile.hub' },
  { translation: 'SOTI MobiControl', value: 'net.soti.mobicontrol.realwear' },
  { translation: 'MobileIron Go', value: 'com.mobileiron.anyware.android' },
  { translation: 'Tetrominos', value: 'com.realwear.tetronimogame' },
  { translation: 'Telepresenz', value: 'app.care4d.demo' },
  { translation: 'com.mobileiron', value: 'com.mobileiron' },
  { translation: 'Zyter', value: 'com.ics.zyter.careconnect' },
  {
    translation: 'MaaS360 MDM for Android',
    value: 'com.fiberlink.maas360.android.control'
  },
  { translation: 'SOHO Demo APP', value: 'com.realwear.sohoacceptance' },
  {
    translation: 'RealWear File Annotation Demo',
    value: 'com.realwear.annotationapplicationdemo'
  },
  { translation: 'Gaana', value: 'com.gaana' },
  { translation: 'Now Mobile', value: 'com.servicenow.requestor' },
  { translation: 'Depstech-View', value: 'com.ipotensic.depstech' },
  { translation: 'MyFilesV3_alpha', value: 'com.realwear.v3filebrowser' },
  { translation: 'SnapSupport', value: 'com.ceino.fluidguy' },
  { translation: 'YouTube', value: 'com.realwear.videostreams.debug' },
  { translation: 'Rainbow', value: 'com.ale.rainbow' },
  {
    translation: 'Blackberry UEM Client',
    value: 'com.rim.mobilefusion.client'
  },
  { translation: 'QR Code Reader 2.3', value: 'me.scan.android.client' },
  { translation: 'Downloands-NoManifest', value: 'com.android.documentsui' },
  { translation: 'Collector for ArcGIS', value: 'com.esri.arcgis.collector' },
  {
    translation: 'Collector for ArcGIS (Early Access)',
    value: 'com.esri.collector'
  },
  { translation: 'FastField Forms', value: 'com.mergemobile.fastfield' },
  { translation: 'Intelex Mobile', value: 'com.intelex.intelexmobile' },
  {
    translation: 'Workspace ONE Intelligent Hub',
    value: 'com.airwatch.vmworkspace'
  },
  { translation: 'GOTOMEETING', value: 'com.gotomeeting' },
  { translation: 'BlueJeans', value: 'com.bluejeansnet.Base' },
  { translation: 'Annotation App', value: 'com.realwear.annotationapp' },
  { translation: 'XCMG demo', value: 'com.realwear.xcmgmaintenance' },
  { translation: 'SmartMFG', value: 'com.rocketpadmedia.smartmfg' },
  { translation: 'Package Name Viewer', value: 'com.csdroid.pkg' },
  { translation: 'ShowMe', value: 'co.blitzz.showme' },
  { translation: 'Webex Meet', value: 'com.cisco.webex.meetings' },
  {
    translation: '(Beta) RealWear Cloud Sync',
    value: 'com.realwear.cloudsync'
  },
  { translation: 'Harvest-test', value: 'com.wearwolf' },
  { translation: 'Greenify', value: 'com.oasisfeng.greenify' },
  { translation: 'Test', value: 'com.king.candycrushsaga' },
  {
    translation: 'speech2text',
    value: 'com.realwear.cognitiveservices.translation'
  },
  { translation: 'Cisco Webex Teams', value: 'com.cisco.wx2.android' },
  { translation: 'Digital Factory Demo', value: 'com.realwear.doam' },
  {
    translation: 'Highway_CheckPoint',
    value: 'com.realwear.gateexamine.tencent'
  },
  { translation: 'New ApK', value: 'com.adr.testpass' },
  { translation: 'APK', value: 'com.rovio.angrybirds' },
  { translation: 'APK123', value: 'com.realwear.foresightsyncmanager' },
  { translation: 'CJ EMM Test', value: 'com.realwear.setupwizard' },
  { translation: 'Velocity for RW (BETA)', value: 'com.wavelink.velocity' },
  { translation: 'SpeakEasy', value: 'com.wavelink.speakeasy' },
  { translation: 'Emikon 35', value: 'com.emikon.emikon' },
  {
    translation: 'Space1-Augmented-Collaboration',
    value: 'it.space1.ac.realwear'
  },
  {
    translation: '(Beta) In document keyword search',
    value: 'com.realwear.documentviewer.genui'
  },
  { translation: 'GPS Demo', value: 'com.example.gpsdemo' },
  { translation: 'Kanepi Demo', value: 'com.kanepi.kanepi_demo' },
  {
    translation: 'ToDo',
    value: 'com.outsystemsteams.garsgj7jpt.valugadev.ToDo'
  },
  { translation: 'Workplace by Facebook', value: 'com.facebook.work' },
  { translation: 'WhatsApp', value: 'com.whatsapp' },
  {
    translation: '(Prototype) Speech Keyboard',
    value: 'com.realwear.keyboardapp'
  },
  { translation: 'My Training', value: 'com.realwear.training' },
  { translation: 'Gemvision', value: 'ar_development.arvuz.mobile' },
  { translation: 'Skype for business', value: 'com.microsoft.office.lync15' },
  { translation: 'Vysor Android Control', value: 'com.koushikdutta.vysor' },
  { translation: 'RealWear Utility Apps', value: 'pl.ttpsc.rwutilityapps' },
  { translation: 'EveryKey', value: 'com.everykey.android' },
  { translation: "Let's View", value: 'com.apowersoft.letsview' },
  { translation: 'Adtance Support', value: 'com.adtance.support' },
  { translation: 'Vidyo', value: 'com.vidyo.VidyoClient' },
  { translation: 'Speech Keyboard', value: 'com.realwear.keyboard' },
  {
    translation: 'Simple Speed Test',
    value: 'org.speedspot.speedspotspeedtest'
  },
  { translation: 'Word Application', value: 'com.microsoft.office.word' },
  { translation: 'Workflow Example', value: 'com.realwear.workflowexample' },
  { translation: 'DJI', value: 'dji.go.v5' },
  { translation: 'Thales Eye', value: 'com.thales.thaleseye' },
  { translation: 'Notepad', value: 'com.atomczak.notepat' },
  { translation: 'MobiControl', value: 'net.soti.mobicontrol.androidwork' },
  { translation: 'Report & Run', value: 'cm.aptoide.pt' },
  { translation: 'PoseDetection', value: 'com.edvard.poseestimation' },
  { translation: 'PoseEstimation', value: 'com.google.mlkit.vision.demo' },
  { translation: 'ABP EoD', value: 'de.oculavis.abp.glass' },
  { translation: 'ADTANCE Smart Service', value: 'com.adtance.smartservice' },
  { translation: 'Blitzz', value: 'co.blitzz.journey' },
  {
    translation: 'Extended Collaboration',
    value: 'it.overit.virtualcollaboration'
  },
  { translation: 'Measurement', value: 'com.google.tango.measure' },
  { translation: 'Waavia 7', value: 'com.waavia.seven.realwear' },
  { translation: 'V-CUBE Collaboration', value: 'com.vcube.pioneer.xSync' },
  { translation: 'XE Essential', value: 'bzh.ama.xperteye' },
  { translation: 'Tulip Player', value: 'co.tulip.player' },
  {
    translation: 'Affinity Vision',
    value: 'com.affinity.vision.enlistav.android'
  },
  {
    translation: 'RealWear Explorer',
    value: 'com.realwear.realwearexplorer.cast'
  },
  { translation: 'Inspection Forms', value: 'wipro.com.lleform' },
  { translation: 'Hazard Detection App', value: 'com.wipro.hazarddetection' },
  { translation: 'D-Morits', value: 'cl.infinit.tsbcommander' },
  { translation: 'Elixar', value: 'com.bondilabs.elixar.production' },
  { translation: 'Whereby', value: 'appear.in.app' },
  { translation: 'QR Scanner', value: 'com.tohsoft.qrcode' },
  { translation: 'Smart Service Plattform', value: 'de.tzm.ssp' },
  { translation: 'Liveon moblie', value: 'jp.ne.liveon.mobile' },
  { translation: 'LiveOnWearable', value: 'jp.ne.liveonWearable.mobile' },
  { translation: 'Scopia', value: 'com.radvision.ctm.android.client' },
  { translation: 'SimplyVideo', value: 'com.simplyvideo' },
  { translation: 'HindSite Application', value: 'com.hindsiteind.kms' },
  { translation: 'Microsoft inTune', value: 'com.microsoft.intune' },
  { translation: 'My Controls', value: 'com.realwear.controlpanel' },

  {
    translation: 'MCL Client',
    value: 'com.mcltechnologies.android.agent.generic'
  },
  { translation: 'J-Scouter-app', value: 'com.jfes.fukuyamaleien.jscouter' },
  { translation: 'Teamviewer Pilot', value: 'com.teamviewer.pilot' },
  { translation: 'XReach', value: 'com.softability.xreachmobile' },
  { translation: 'Remote Support', value: 'com.sandvik.smrt.remotesupport' },
  { translation: 'NetConnect X | LYF', value: 'com.northbridge.nextgen' },
  { translation: 'Pexip', value: 'com.pexip.android' },
  {
    translation: 'Poly RealPresence Mobile',
    value: 'com.polycom.cmad.mobile.android.phone'
  },
  { translation: 'Micollab Client', value: 'com.mitel.ucang' },
  {
    translation: 'ONEAssist',
    value: 'com.diginonymous.iec.oneassist.realwear'
  },
  { translation: 'SAFR Recognition', value: 'com.real.argus' },
  { translation: 'Itorum', value: 'ru.itorum.remotehelper' },
  { translation: 'MTS', value: 'ru.mts.remotehelper' },
  { translation: 'Er Telecom', value: 'ru.ertelecom.remotehelper' },
  {
    translation: '動画アップローダー',
    value: 'jp.jmacs.moviefileuploaderfornspc'
  },
  {
    translation: 'Scan-IT to Office',
    value: 'com.tecit.android.bluescanner.office'
  },
  { translation: 'Orca Scan', value: 'com.orcascan.bulkbarcodescanner' },
  { translation: 'Maxima Telecom', value: 'ru.maxima.remotehelper' },
  {
    translation: 'Augmented Collaboration',
    value: 'it.space1.ac.realwear.demo'
  },
  { translation: 'WiFi', value: 'diewland.settings.wifi' },
  { translation: 'WiFi', value: 'org.logpedia.wifi' },
  { translation: 'XR-Sense', value: 'com.toozon.realshell' },
  { translation: 'Cisco AMP Protect', value: 'com.cisco.amp' },
  { translation: 'Google Meet', value: 'com.google.android.apps.meetings' },
  { translation: 'SME Caller', value: 'com.abi.sme.a' },
  { translation: 'Barcode Reader', value: 'com.realwear.barcodereader' },
  { translation: 'rise', value: 'com.id1mkl2ywshfiru15cwj' },
  { translation: 'Checklist 2.0', value: 'com.sme.smechecklist' },
  { translation: 'HPE Myroom', value: 'com.hpe.myroom.mobile' },
  { translation: 'Droid Cam app', value: 'com.dev47apps.droidcam' },
  { translation: 'Checklist 1.0', value: 'com.sme.checklist' },
  { translation: 'AVA', value: 'com.synology.livecam' },
  { translation: 'IP Webcam', value: 'com.pas.webcam' },
  { translation: 'Test App', value: 'ca.webility.realwear' },
  { translation: 'Augmented Support', value: 'com.viumore.augmentedsupport' },
  { translation: 'REFLEKT Remote', value: 'com.reflekt.remote.glass' },
  { translation: 'SMARTMINE', value: 'com.microsoft.d365.fs.mobile' },
  { translation: 'measurement', value: 'com.grymala.aruler' },
  {
    translation: 'Westernacher UI5',
    value: 'com.example.generator.rfgeneratorapp'
  },
  { translation: 'DS Audio', value: 'com.synology.DSaudio' },
  {
    translation: 'RigNet AVI Encoder',
    value: 'com.digitalbarriers.androidencoder'
  },
  {
    translation: 'Live Assist',
    value: 'coza.thosedevs.liveassist.androidclient'
  },
  { translation: 'Skype', value: 'com.skype.raider' },
  { translation: 'forms2mobile', value: 'uk.co.forms2mobile' },
  { translation: 'Compass', value: 'net.androgames.compass' },
  { translation: 'Worklink', value: 'com.scopear.ScopeApp' },
  { translation: 'TEST APP', value: 'jp.rozetta.t4po_demo' },
  { translation: 'Workmate', value: 'com.sailors.workmate' },
  { translation: 'Work Assist', value: 'maw.roadwarrioraudit' },
  { translation: 'Vista', value: 'com.eyllo.vista' },
  { translation: 'testapp', value: 'com.evolaris.testapp' },
  {
    translation: 'Augmented Content',
    value: 'com.essert.augmentedinstructions'
  },
  { translation: 'Zoho Lens', value: 'com.zoho.lens.customer' },
  {
    translation: 'Passerelle',
    value: 'io.supportsquare.passerelle.remotesupport'
  },
  { translation: 'Skype', value: 'com.skype.polaris' },
  { translation: 'MobileServ', value: 'com.redlist.realwear' },
  { translation: 'Acty', value: 'com.acty.client' },
  { translation: 'VG Execution', value: 'com.kiksar.valgenesis' },
  { translation: 'Mobility', value: 'com.nmwco.mobility.client' },
  {
    translation: 'Teamviewer_For_Android',
    value: 'com.teamviewer.teamviewer.market.mobile'
  },
  { translation: 'AirMirror', value: 'com.sand.airmirror' },
  { translation: 'AirDroid', value: 'com.sand.airdroid' },
  { translation: 'Google Maps', value: 'com.google.android.apps.maps' },
  {
    translation: 'Dynamics 365 Remote Assist',
    value: 'com.microsoft.ramobile'
  },
  { translation: 'Next plus demo', value: 'eu.gt.starter' },
  { translation: 'G. Play', value: 'com.android.vending' },
  { translation: 'Utility AR', value: 'com.utilityar.workflow' },
  { translation: 'EVOCALL HMT-1', value: 'net.evolaris.eyecallrecon' },
  { translation: 'LEWA Service', value: 'com.adtance.lewaservice' },
  { translation: 'Dou', value: 'com.google.android.apps.tachyon' },
  { translation: 'Firefox', value: 'org.mozilla.firefox' },
  { translation: 'Movilidad', value: 'ot.ternium.mdwclient' },
  { translation: 'IBM Handsfree Remote Assist', value: 'com.ibm.dml.ft' },
  { translation: 'Simplifier', value: 'com.iTiZZiMO.Simplifier' },
  { translation: 'Line', value: 'jp.naver.line.android' },
  { translation: 'SimplyVideo', value: 'lv.netcore.simplyvideo' },
  { translation: 'FoxNet', value: 'co.za.foxit.foxittracker' },
  {
    translation: 'Telepresence',
    value: 'gadgeon.amplify.com.webrtclivechat.staging'
  },
  { translation: 'MIX ASSISTANCE', value: 'de.indeca4d.mix.assistance' },
  { translation: 'Gmail', value: 'com.google.android.gm' },
  { translation: 'Engine Eyewear', value: 'co.za.thosedevs.engineeyewear' },
  { translation: 'Engine Ar LMS', value: 'com.thosedevs.enginearlmsgat' },
  { translation: 'XMReality', value: 'xmreality.com.xmreality' },
  {
    translation: 'AutoStart and Stay!',
    value: 'com.atasoglou.autostartandstay'
  },
  { translation: 'VIVAR', value: 'com.maxst.vivar.factory' },
  { translation: 'SuperSu', value: 'eu.chainfire.supersu' },
  { translation: 'eComplaince', value: 'com.ecompliance.mobile' },
  { translation: 'zoom', value: 'com.nextcloud.client' },
  {
    translation: 'Serviceplus Remote Support',
    value: 'com.twilio.video.quickstart.kotlin'
  },
  { translation: 'Simply Video', value: 'com.simplyvideo.realview' },
  { translation: 'RDP', value: 'com.microsoft.rdc.android' },
  { translation: 'GPS Track', value: 'mil.trmc.osd.locationtracker' },
  { translation: 'Vidyard', value: 'com.govideomobile' },
  { translation: 'Spider', value: 'com.eyllo.spider' },
  { translation: 'Devils Canyon', value: 'com.realwear.devilscanyon' },
  { translation: 'Doka Remote Instructor', value: 'com.doka.remoteinstructor' },
  { translation: 'FitMachine', value: 'com.movusmobileapp' },
  { translation: 'Geopal APK', value: 'com.edm.geopal' },
  { translation: 'myProject', value: 'no.wise.myProject' },
  { translation: 'Wechat', value: 'com.tencent.mm' },
  { translation: 'Proceedix Enterprise', value: 'com.proceedix.app' },
  {
    translation: 'Work Instructions',
    value: 'com.bondilabs.workinstructions.prod'
  },
  {
    translation: 'Assembly Workflow',
    value: 'com.knowledge_insight.cadmes_workflow'
  },
  { translation: 'Google Play Services AR', value: 'com.google.ar.core' },
  { translation: 'mcloud', value: 'com.agnity.assetcare.realwear' },
  {
    translation: 'ABB Remote Insights Glass',
    value: 'com.reflekt.remote.abb.abbremoteinsight.glass'
  },
  { translation: 'DigitalLens', value: 'com.EY.DigitalLens' },
  { translation: 'Google PDF', value: 'com.google.android.apps.pdfviewer' },
  { translation: 'VLC Player', value: 'org.videolan.vlc' },
  { translation: 'GoogleDrive', value: 'com.google.android.apps.docs' },
  { translation: 'hangout', value: 'com.google.android.talk' },
  { translation: 'AR_Work_Hulamin', value: 'co.za.thosedevs.liveassistar' },
  { translation: 'SharePoint', value: 'com.microsoft.sharepoint' },
  { translation: 'Authenticator', value: 'com.azure.authenticator' },
  { translation: 'FileCommander', value: 'com.mobisystems.fileman' },
  { translation: 'Media Player', value: 'com.realwear.videoplayer' },
  {
    translation: 'KDDI Safety Manager',
    value: 'jp.co.optim.bizagent.smsm.store'
  },
  { translation: 'Messenger', value: 'com.facebook.orca' },
  { translation: 'DJI Go', value: 'dji.go.v4' },
  { translation: 'Bitnamic', value: 'net.bitnamic.vios' },
  { translation: 'MS Power Apps for Android', value: 'com.microsoft.msapps' },
  { translation: 'SightCall', value: 'com.sightcall.agent' },
  { translation: 'Adobe Acrobat Reader', value: 'com.adobe.reader' },
  { translation: 'ConnectWise', value: 'com.screenconnect.androidclient' },
  { translation: 'Adobe connect', value: 'air.com.adobe.connectpro' },
  { translation: 'VNC Viewer', value: 'com.realvnc.viewer.android' },
  { translation: 'Signal', value: 'org.thoughtcrime.securesms' },
  { translation: 'EyeTech', value: 'de.oculavis.primeserv.glass' },
  { translation: 'VIZCHECK TEST', value: 'com.cerekon.vizcheck.test' },
  { translation: 'Youtube test', value: 'com.google.android.youtube' },
  { translation: 'file viewer', value: 'com.sharpened.androidfileviewer' },
  { translation: 'mp3', value: 'com.zing.mp3' },
  { translation: 'My Grafana', value: 'com.earyzhe.grafana_app' },
  { translation: 'Office 365', value: 'com.microsoft.office.officehubrow' },
  {
    translation: 'Document Reader',
    value: 'com.solotech.apps.document.viewer.reader.scaner.pdfreader'
  },
  {
    translation: 'Microsoft Power Point',
    value: 'com.microsoft.office.powerpoint'
  },
  { translation: 'Lifesize', value: 'com.mirial.lifesizecloud' },
  { translation: 'Hexnode MDM', value: 'com.hexnode.hexnodemdm' },
  {
    translation: 'Teamviewer: Realwear Add-On',
    value: 'com.teamviewer.quicksupport.addon.realwear'
  },
  { translation: 'he.net Network Tools', value: 'net.he.networktools' },
  { translation: 'Ping', value: 'net.WisdomStar.Ping' },
  { translation: 'miperf', value: 'com.nextdoordeveloper.miperf.miperf' },
  { translation: 'pingtools', value: 'ua.com.streamsoft.pingtools' },
  { translation: 'OneNote', value: 'com.microsoft.office.onenote' },
  { translation: 'Cisco WebEx Calling', value: 'com.cisco.webexcalling' },
  { translation: 'Quickassist', value: 'me.tuple.activity_quick_assist' },
  {
    translation: 'MSAM RealWear',
    value: 'com.yourredlist.kmmrealwear.androidApp'
  },
  { translation: 'BIM360 Team', value: 'com.autodesk.bim360team' },
  { translation: 'BlueBeam', value: 'com.bluebeam.drawings' },
  { translation: 'PlanGrid', value: 'com.plangrid.android' },
  { translation: 'PDF reader', value: 'com.am.pdfviewer' },
  { translation: 'AnyDesk', value: 'com.anydesk.anydeskandroid' },
  { translation: 'Anydesk remote helper', value: 'com.anydesk.adcontrol.ad1' },
  { translation: 'swymed23.9', value: 'org.swymed' },
  {
    translation: 'Augmented Operator Advisor',
    value: 'com.schneiderelectric.AugmentedOperator'
  },
  {
    translation: 'Ignition Perspective',
    value: 'com.inductiveautomation.perspectiveapp'
  },
  { translation: 'FieldMaster Viewer', value: 'go.yko.safety' },
  { translation: 'aron', value: 'deepfine.ai' },
  { translation: 'JuJoTech Remote', value: 'com.jujo.fusionremote.zoom' },
  { translation: 'PDF', value: 'com.officetool.pdfreader2018.pdfviewer' },
  { translation: 'Field 360', value: 'mobi.com.field360' },
  {
    translation: '4Remote',
    value: 'com.zerintia.continental.international.pim.mainApp'
  },
  {
    translation: 'Doc X reader',
    value: 'com.skydot.docxreader.docx.docxviewer.document.doc.office.viewer.reader.word'
  },
  { translation: 'Asset and Inventory', value: 'com.ventipix.assetManager' },
  { translation: 'QGIS TEST', value: 'ch.opengis.qfield' },
  { translation: 'SpeedTest', value: 'au.com.hindsiteind.speedtest' },
  { translation: 'SAP Field Service Management', value: 'com.sap.fsm' },
  { translation: 'InTouch Mobile', value: 'com.aveva.insight.mobile' },
  { translation: 'GuangYangTest', value: 'com.example.testrealwearapp' },
  { translation: 'FLIR Tools Mobile 2019', value: 'com.flir.viewer' },
  {
    translation: 'Microsoft Defender for Android',
    value: 'com.microsoft.scmx'
  },
  { translation: 'CardClcik', value: 'com.DefaultCompany.CardTest1' },
  { translation: 'TwinEye', value: 'com.wideum.twineye' },
  { translation: 'IPWebcampro', value: 'com.pas.webcam.pro' },
  {
    translation: 'Radio Paradise',
    value: 'com.earthflare.android.radioparadisewidget.gpv2'
  },
  { translation: 'Plex', value: 'com.plexapp.android' },
  {
    translation: 'Home Assistant',
    value: 'io.homeassistant.companion.android'
  },
  { translation: 'WhatsApp Business', value: 'com.whatsapp.w4b' },
  { translation: 'Demo app', value: 'com.example.rwanov' },
  { translation: 'Document Viewer', value: 'com.realwear.documentviewer' },
  { translation: 'QR Code Reader', value: 'tw.mobileapp.qrcode.banner' },
  { translation: 'ericsson', value: 'air.com.ericssonremoteaccess.mobile' },
  { translation: 'Talon', value: 'com.talon.view' },
  { translation: 'Teamwork Projects', value: 'net.teamworkpm.phone' },
  { translation: 'PickerMerge', value: 'fi.finnid.pickermerge' },
  { translation: 'AR_Demo_Realwear_0402', value: 'com.silvereye.realwear' },
  { translation: 'RMS', value: 'com.rmssp' },
  { translation: 'Controller Update', value: 'com.rms' },
  { translation: 'CTS Tech Support Splash', value: 'com.techsupport' },
  { translation: 'InfraSpec', value: 'de.infraserv.app' },
  { translation: 'FusionAR', value: 'com.jujo.fusionapp' },
  { translation: 'Assist', value: 'com.imaginate.assist' },
  { translation: 'UC Browser', value: 'com.UCMobile.intl' },
  { translation: 'SEAMCOM Connect', value: 'de.gecko.rainbow.seamcom.connect' },
  {
    translation: 'HelloScene ARCORE',
    value: 'com.google.ar.sceneform.samples.hellosceneform'
  },
  { translation: 'K-9 Email Client', value: 'com.fsck.k9' },
  { translation: 'Picomto', value: 'com.allianzes.picomto' },
  { translation: 'Picomto Démo', value: 'com.allianzes.demo' },
  { translation: 'Dashboard', value: 'com.osterhoutgroup.dashboard' },
  { translation: 'Notepad', value: 'com.tomaszmarzeion.notepad' },
  { translation: 'RTSP', value: 'com.miv.rtspcamera' },
  { translation: 'OnStart', value: 'com.trinus.onstart' },
  { translation: 'Larix Broadaster', value: 'com.wmspanel.larix_screencaster' },
  { translation: 'Wifi Analyzer', value: 'com.farproc.wifi.analyzer' },
  {
    translation: 'Skillworx - cloud-based guided workflow',
    value: 'pl.ttpsc.training'
  },
  {
    translation: 'Fusion Remote for MS-Teams',
    value: 'com.jujo.fusionremote.bluejeans'
  },
  { translation: 'PrimeServ EyeTech', value: 'de.oculavis.primeserv.mobile' },
  { translation: 'OpenTouch Conversation', value: 'com.alu.myic.opentouch' },
  { translation: 'HVC', value: 'com.ics.zyter.virtualvideovisit.hmt' },
  { translation: 'Genetec Mobile', value: 'com.genetec.platformmobile' },
  { translation: 'FortiClient VPN', value: 'com.fortinet.forticlient_vpn' },
  {
    translation: 'Global Protect',
    value: 'com.paloaltonetworks.globalprotect'
  },
  {
    translation: 'Enterprise Agent',
    value: 'com.gears42.enterpriseagent.realwear'
  },
  { translation: 'Nix Agent', value: 'com.nix' },
  { translation: 'SWXDemo', value: 'pl.ttpsc.swxdemo' },
  { translation: 'SWX-Scan', value: 'pl.ttpsc.swxscan' },
  { translation: 'SWX-Settings', value: 'pl.ttpsc.swxsettings' },
  { translation: 'XPERTSYSTEM PLUS', value: 'com.softfoundry.esfpplus' },
  { translation: 'Meet Guide', value: 'com.meeting.googlemeetguide' },
  { translation: 'ACTY-Vator (Skin NP&A)', value: 'com.acty.skinappactivator' },
  { translation: 'RingCentral Meetings', value: 'com.ringcentral.meetings' },
  { translation: 'Facetime', value: 'com.nextup.increatephones.van' },
  { translation: 'ProntoForms', value: 'com.truecontext.prontoforms' },
  { translation: 'PDANet', value: 'com.pdanet' },
  { translation: 'EVOCALL', value: 'net.evolaris.evocallsmartglass' },
  { translation: 'VidyoConnect', value: 'com.vidyo.neomobile' },
  { translation: 'FAST Speed Test', value: 'com.netflix.Speedtest' },
  { translation: 'iForm', value: 'com.zerion.apps.iform.main' },
  { translation: 'OKTA', value: 'com.okta.android.mobile.oktamobile' },
  { translation: 'Demo Applicatie', value: 'com.pandatechnologies.weh' },
  { translation: 'Virnect Remote 1.4', value: 'com.virnect.remote.hmt_series' },
  { translation: 'A360', value: 'com.autodesk.a360' },
  { translation: 'Virnect Remote 2.1', value: 'com.virnect.remote.realwear2' },
  {
    translation: 'Cisco Anyconnect',
    value: 'com.cisco.anyconnect.vpn.android.avf'
  },
  { translation: 'KnowledgeGuide Player', value: 'com.example.myapplication' },
  { translation: 'RW-FileReader', value: 'com.example.rw_filereader' },
  {
    translation: 'ABB Remote Insight',
    value: 'com.reflekt.remote.abb.abbremoteinsight'
  },
  {
    translation: 'Centrotherm CS Testsoftware',
    value: 'com.example.centrotherm_java'
  },
  { translation: 'Удаленный доктор', value: 'ru.uni3dlabs.RemoteDocGlass' },
  {
    translation: 'SecurityBrowser for Office365 - Nextset',
    value: 'jp.nextset.SSO'
  },
  { translation: 'AutoCAD Mobile', value: 'com.autodesk.autocadws' },
  {
    translation: 'Cisco Network Setup Assistant',
    value: 'com.cisco.cpm.spw.android.wifisupplicant'
  },
  { translation: 'SmartSheet', value: 'com.smartsheet.android' },
  { translation: 'smapone', value: 'com.smapone.runtime' },
  { translation: 'idaptive', value: 'com.centrify.mdm.samsung' },
  { translation: 'Opera Browser', value: 'com.opera.browser' },
  { translation: 'BlueMail', value: 'me.bluemail.mail' },
  { translation: 'ADNOC Digital Workforce', value: 'com.adnoc.digitalwork' },
  { translation: 'Telegram Messenger', value: 'org.telegram.messenger' },
  { translation: 'Mobile Access', value: 'com.aveva.indusoft.mobileaccess' },
  {
    translation: 'Work Tasks Pro',
    value: 'com.schneiderelectric.worktaskspro'
  },
  { translation: 'Companion', value: 'com.realware.companionapp' },
  { translation: 'Astra Streaming Studio', value: 'miv.astudio' },
  { translation: 'BASF Trait Development', value: 'ca.vdts.hmt.basf_audit' },
  { translation: 'Webview', value: 'com.google.android.webview' },
  { translation: 'Calculator', value: 'com.google.android.calculator' },
  {
    translation: 'Bentley Navigator Mobile',
    value: 'com.bentley.android.navigator'
  },
  {
    translation: 'Microsoft Stream',
    value: 'com.microsoft.stream'
  },
  { translation: 'Brumbaugh Scaling', value: 'ca.vdts.hmt.scaling' },
  { translation: 'BASF Agronomic Services', value: 'ca.vdts.hmt.basf' },
  { translation: 'JITSI', value: 'org.jitsi.meet' },
  { translation: 'Forestry', value: 'ca.vdts.hmt.forestry' },
  { translation: 'MS Power Automation', value: 'com.microsoft.flow' },
  {
    translation: 'outlook',
    value: 'com.emailclient.mailchecker.outlook.hotmail'
  },
  {
    translation: 'Xerox Print Service Plug In',
    value: 'com.xerox.printservice'
  },
  {
    translation: 'Blue light filter',
    value: 'com.eyefilter.nightmode.bluelightfilter'
  },
  { translation: 'ESS Assist', value: 'com.simplyvideo.companion' },
  { translation: 'Secure Hub', value: 'com.zenprise' },
  { translation: 'Data Traffic Monitor', value: 'com.radioopt.widget' },
  { translation: 'BASF Winter Wheat', value: 'ca.vdts.hmt.basf_winter_wheat' },
  { translation: 'PDF', value: 'pdf.reader' },
  { translation: 'Maas360 Mail', value: 'com.fiberlink.maas360.android.pim' },
  { translation: 'MaaS360', value: 'com.fiberlink.maas360.android.control.lg' },
  { translation: 'Grading', value: 'ca.vdts.hmt.grading' },
  {
    translation: 'BASF Research Farm',
    value: 'ca.vdts.hmt.basf_research_farm'
  },
  { translation: 'Web Workspace ONE', value: 'com.airwatch.browser' },
  { translation: 'KHS-ARS', value: 'de.ubimax.khs.client' },
  { translation: 'Support', value: 'com.bomgar.thinclient.android' },
  {
    translation: 'Secure Viewer',
    value: 'com.fiberlink.maas360.android.secureviewer'
  },
  { translation: 'Maas360 Docs', value: 'com.fiberlink.maas360.android.docs' },
  { translation: 'Filemanager', value: 'com.alphainventor.filemanager' },
  { translation: 'Floating Draw', value: 'onscreen.draw' },
  { translation: 'Flashlight', value: 'com.simplemobiletools.flashlight' },
  { translation: 'Homescreen', value: 'com.ss.squarehome2' },
  { translation: 'Icons', value: 'com.whicons.iconpack' },
  { translation: 'GenUI Scroll Spike', value: 'com.genui.searchResults' },
  { translation: 'Vieaura HGV Daily Checks', value: 'com.vieaura.vinspection' },
  { translation: 'Tunnel', value: 'com.mobileiron.tunnel.android.release' },
  { translation: 'Skype', value: 'com.skype.insiders' },
  { translation: 'Tunnel Workspace', value: 'com.airwatch.tunnel' },
  {
    translation: 'Skillworx Assisted Worker',
    value: 'pl.ttpsc.skillworx.swxassistedworker'
  },
  { translation: 'Kaspersky', value: 'com.kaspersky.kes' },
  {
    translation: 'Veridas test',
    value: 'com.veridas.vdappsaas.identidasselfiealive'
  },
  { translation: 'REFLEKT Remote', value: 'com.reflekt.remote' },
  { translation: 'Softwood Scaling', value: 'ca.vdts.hmt.soft_scaling' },
  {
    translation: 'Auburn Horticulture',
    value: 'ca.vdts.hmt.auburn_horticulture'
  },
  {
    translation: 'Ruler App - Camera Tape Measure',
    value: 'com.grymala.photoruler'
  },
  { translation: 'JSバックアップ', value: 'jp.co.johospace.backup' },
  { translation: 'Hardwood Scaling', value: 'ca.vdts.hmt.hard_scaling' },
  { translation: 'Telepresnz user testing', value: 'app.care4d.smartvision' },
  { translation: 'Expert Link', value: 'com.librestream.safran.expertlink' },
  { translation: 'Smart Eye V55', value: 'net.evolaris.palfinger.smarteye' },
  {
    translation: 'Vieaura Joint Use Pole Audit',
    value: 'com.vieaura.jupoleaudit'
  },
  { translation: 'Google street View', value: 'com.google.android.street' },
  { translation: 'BASF Greenhouse', value: 'ca.vdts.hmt.basf_greenhouse' },
  { translation: 'Telegram', value: 'org.telegram.messenger.web' },
  { translation: 'BASF Veg Seeds', value: 'ca.vdts.hmt.basf_vegetable_seeds' },
  {
    translation: '4REMOTE',
    value: 'com.zerintia.continentalauto.international'
  },
  { translation: 'Swymed 17.4.0', value: 'org.veamea.swymed' },
  { translation: 'Antivirus', value: 'com.trendmicro.tmmspersonal' },
  { translation: 'Pack Grading', value: 'ca.vdts.hmt.nw_grading' },
  { translation: 'Process Eye', value: 'com.wideum.processeye' },
  { translation: 'Dalux Field', value: 'air.dk.dalux.daluxqa' },
  {
    translation: 'Ben Hokum & Son Scaling',
    value: 'ca.vdts.hmt.hokum_scaling'
  },
  { translation: 'pCloud', value: 'com.pcloud.pcloud' },
  { translation: 'QR Reader', value: 'de.markusfisch.android.binaryeye' },
  { translation: 'WiCollab', value: 'com.inspectron.mobile' },
  { translation: 'VDTS Demo Trials', value: 'ca.vdts.hmt.vdts_demo_trials' },
  {
    translation: 'TEPPAnavi Picking',
    value: 'jp.co.tetch.android.exactly_voicenavi_picking'
  },
  { translation: 'osMO Pro', value: 'com.unify.osmo' },
  { translation: 'Power Grids Assist', value: 'de.oculavis.hitachi.glass' },
  { translation: 'Kane Scaling', value: 'ca.vdts.hmt.kane_scaling' },
  { translation: 'SmartHGF', value: 'com.MIXreality.WEGplataformaColor' },
  { translation: 'QR code reader', value: 'com.teacapps.barcodescanner' },
  { translation: 'Axgate VPN', value: 'com.axgate.vpn' },
  {
    translation: 'AR Remote Assist-Glass',
    value: 'com.fii.app.glass_remoteassist'
  },
  {
    translation: 'セキュリティブラウザ for クラウド サテライトオフィス',
    value: 'jp.sateraito.SecurityBrowserForCloud'
  },
  { translation: 'Smart IRA', value: 'com.rex.smartira' },
  { translation: 'PowerBI', value: 'com.microsoft.powerbim' },
  { translation: 'WOapp', value: 'com.ebookfrenzy.lippertapp' },
  {
    translation: 'Live Transcribe',
    value: 'com.google.audio.hearing.visualization.accessibility.scribe'
  },
  {
    translation: 'Canfor Silviculture',
    value: 'ca.vdts.hmt.canfor_silviculture'
  },
  {
    translation: 'Automine Remote Support',
    value: 'appinventor.ai_angry_deity.Remote_Start'
  },
  { translation: 'Contextere', value: 'com.Contextere.Contextere' },
  { translation: 'BIM 360', value: 'com.autodesk.bim360.docs' },
  { translation: 'Scaling Backend', value: 'ca.vdts.hmt.backend_scaling' },
  {
    translation: 'Thermal Camera For FLIR One',
    value: 'georg.com.flironetest_01'
  },
  { translation: 'Alberta Scaling', value: 'ca.vdts.hmt.alberta_scaling' },
  { translation: 'Hippo VC', value: 'com.ics.zyter.virtualvideovisit.newhmt' },
  { translation: 'Joe Knows Energy', value: 'io.cordova.hellocordova' },
  { translation: 'Go To Assist', value: 'com.logmein.g2acustomer' },
  { translation: 'Thermal FLIR One', value: 'com.flir.flirone' },
  { translation: 'End Tally', value: 'ca.vdts.hmt.endtally' },
  { translation: 'Klein Boroscope', value: 'com.cem.amos.et20player' },
  { translation: 'Tencent Meeting', value: 'com.tencent.wemeet.app' },
  { translation: 'GTL Grading', value: 'ca.vdts.hmt.gtl_grading' },
  {
    translation: 'Smart Assistance',
    value: 'com.easysol.smart_assistance_app'
  },
  { translation: 'EasyCamera', value: 'com.easysol.camera.debug' },
  { translation: 'Corteva Training', value: 'ca.vdts.hmt.corteva_training' },
  { translation: 'ABB RAISE', value: 'com.abb.acty.client' },
  { translation: 'Arvis', value: 'com.vale.arvisglasses' },
  { translation: 'Repsol_Assist_Spanish', value: 'com.ns.repsoassist' },
  { translation: 'AR Worker', value: 'com.axians.augmentedworker' },
  { translation: 'Lookout', value: 'com.lookout' },
  { translation: 'DSO Sentinel Rw Prototype', value: 'com.companyname.rwpt' },
  {
    translation: 'JourneyApps Beta',
    value: 'com.embarkmobile.branding.journeyenterprise'
  },
  { translation: 'Blitzz Player', value: 'com.example.samarth.videoplayer' },
  { translation: 'Instandhaltung', value: 'com.wInstandhaltung_9438710' },
  { translation: 'Lorinspect', value: 'com.engie.mobile.lorin' },
  { translation: 'Chemy1', value: 'com.companyname.Chemy' },
  { translation: 'Tacit Expert', value: 'com.hundredspires.tacit' },
  { translation: 'SeeIt', value: 'com.logmein.seeit.customer' },
  { translation: 'RescueAssist', value: 'com.logmein.rescueassist' },
  { translation: 'RWDataEditor', value: 'com.example.rwdataeditor' },
  { translation: 'WeInspect', value: 'com.iris.inspections' },
  { translation: 'Pocket Magnifier', value: 'com.exatools.magnifier' },
  { translation: 'Data editor Demo', value: 'com.example.rwdataeditordemo' },
  { translation: 'SAP JAM', value: 'com.sap.jam.android' },
  { translation: 'TSS', value: 'com.secondsightar.testingandsubsea' },
  { translation: 'VIPs Glass', value: 'com.vipsglass' },
  { translation: 'BHI RMS Table', value: 'dp.com.example.database' },
  { translation: 'F5 Secure Access', value: 'com.f5.edge.client_ics' },
  {
    translation: 'CONNECTED WORKER',
    value: 'com.quantumchaos.connectedworker'
  },
  { translation: 'Zoom Launcher', value: 'gov.pnnl.zoomlauncher' },
  { translation: 'Smartechs', value: 'com.ciright.smartechsrealware' },
  {
    translation: 'MobiControl Device Agent for Realwear',
    value: 'net.soti.mobicontrol.enterprise.realwear'
  },
  { translation: 'Droid Hardware Info', value: 'com.inkwired.droidinfo' },
  { translation: 'KLEANZ', value: 'com.NexcorTech.KLEANZMobile' },
  { translation: 'SOMAX', value: 'com.somax.cumulus.universal' },
  { translation: 'REVER', value: 'com.reverscore.mobileapp' },
  {
    translation: 'Alexa Voice Library',
    value: 'com.willblaschko.android.alexavoicelibrary'
  },
  { translation: 'LindeGO', value: 'de.oculavis.lindego.glass' },
  { translation: 'DUDE', value: 'com.dudesolutions.assetessentials' },
  { translation: 'EasySite', value: 'com.sanofi.fr.easysite.public' },
  { translation: 'Total AV Virus Protection', value: 'com.totalav.android' },
  { translation: 'xASL_PA-release', value: 'org.toby.telTV.aslPA' },
  { translation: 'xcctvtelenttv', value: 'telent.benwalker.telenttv' },
  { translation: 'xEBN App', value: 'org.toby.telTV.ebn' },
  { translation: 'xVIDs-release', value: 'org.toby.telTV.vids' },
  { translation: 'xVisioWave-release v1-1', value: 'org.toby.telTV.vwDvr' },
  { translation: 'xTecton_DVR-release', value: 'walker.ben.test' },
  { translation: 'Video Camera Pause', value: 'com.neoadn.neoadnvideocamera' },
  { translation: '폴더동기화', value: 'dk.tacit.android.foldersync.full' },
  { translation: '고프로', value: 'com.gopro.smarty' },
  { translation: '캐논', value: 'jp.co.canon.ic.cameraconnect' },
  { translation: 'Syncthing', value: 'com.nutomic.syncthingandroid' },
  { translation: 'Clock', value: 'com.google.android.deskclock' },
  { translation: 'RescueLens', value: 'com.logmein.rescuemobile' },
  {
    translation: 'Contact Satisloh',
    value: 'com.tohsoft.mail.email.emailclient'
  },
  { translation: 'Anymail', value: 'emailapp.anymail' },
  {
    translation: 'E-Mail App with Links',
    value: 'park.outlook.sign.in.client'
  },
  {
    translation: 'Meteor',
    value: 'meteor.test.and.grade.internet.connection.speed'
  },
  { translation: 'SafetyCheck', value: 'com.nsw.patrolreport' },
  { translation: '现场记录助手', value: 'com.qinren.xcjlzs' },
  { translation: '现场记录助手-离线版', value: 'com.qinren.xcjlzslx' },
  {
    translation: 'Wi-Fi Switcher for Android',
    value: 'com.softmediacts.wifiswitch'
  },
  { translation: 'FlexExpert', value: 'teleportation.expert.webrtc' },
  {
    translation: 'Frontline Workplace',
    value: 'de.ubimax.frontline.client.smartphone'
  },
  { translation: 'AR측정', value: 'com.peace.ArMeasure' },
  { translation: 'MSF(MAXST)', value: 'com.maxst.msf' },
  { translation: 'MAXWORK', value: 'com.maxst.mint.app' },
  { translation: '소음측정', value: 'kr.aboy.sound' },
  { translation: 'Central_AR', value: 'com.GHD.ARCentral' },
  {
    translation: 'NOTE',
    value: 'notes.notebook.memo.pad.color.notepad.locker'
  },
  { translation: 'Eyesight', value: 'io.pristine.glass.eyesight' },
  { translation: 'Expert Teleportation', value: 'teleportation.expert.jitsi' },
  {
    translation: 'TRUMPF Visual Assistance',
    value: 'de.oculavis.trumpf.glass'
  },
  { translation: 'Navix Offline', value: 'com.tofnatech.navix' },
  {
    translation: 'Taskimo Demo',
    value: 'com.tofnatech.ford.taskimo.main.debug'
  },
  { translation: 'TRUMPF Waiver', value: 'com.trumpf.us.waiverapp' },
  { translation: 'Brave Browser', value: 'com.brave.browser' },
  { translation: 'Development MMV', value: 'com.mymeetingvideo.app' },
  { translation: 'Meraki SM', value: 'com.meraki.sm' },
  { translation: 'eAudit', value: 'com.wipro.eAudit' },
  { translation: 'Inspection Form', value: 'wipro.com.inspection' },
  { translation: 'Hazard Detection', value: 'com.wipro.hazarddetectiongauges' },
  { translation: 'eAssurance', value: 'com.wipro.eassurance.eassurance' },
  { translation: 'DGXForm', value: 'com.jasonette' },
  {
    translation: 'Operating Procedures',
    value: 'com.wipro.operatingprocedures'
  },
  { translation: 'TestApp_v0', value: 'com.example.primerparcial_v0' },
  { translation: 'Wipro mAudit', value: 'com.wipro.Wipro_mAudit' },
  { translation: 'Remote Eye Cepsa', value: 'com.wideum.cepsa' },
  {
    translation: 'ERP Pick Application',
    value: 'pfizer.com.smartglasswarehousepickapp.debug'
  },
  { translation: 'ServiceNow', value: 'com.servicenow.fulfiller' },
  { translation: 'ODUploader', value: 'com.stellartech.od_upload' },
  {
    translation: 'LCD Burn in Wiper',
    value: 'appinventor.ai_avaworks00.LCDBurnInWiper'
  },
  { translation: 'Chime', value: 'com.amazon.chime' },
  { translation: 'Code Scanner', value: 'fr.smarquis.qrcode' },
  { translation: 'Cisco Jabber', value: 'com.cisco.im' },
  {
    translation: 'TF Space1 Collaboration',
    value: 'it.space1.virtualcollaboration'
  },
  { translation: 'GPS Tester', value: 'com.chartcross.gpstest' },
  { translation: 'OneSync', value: 'com.ttxapps.onesyncv2' },
  { translation: 'REx Test', value: 'com.dxc.rex.hmt.test' },
  { translation: 'Remote Expert Stage', value: 'com.dxc.rex.hmt.stage' },
  { translation: 'FSI GO', value: 'com.fsigo' },
  { translation: 'Enova App', value: 'com.enova.mobile' },
  { translation: 'Mobile Tools', value: 'com.novachem.mobiletools' },
  { translation: 'THE LOTOR', value: 'com.knowledge_insight.loto_workflow' },
  { translation: 'THE SPETEXTOR', value: 'com.knowledge_insight.speechtotext' },
  {
    translation: 'XRecorder',
    value: 'videoeditor.videorecorder.screenrecorder'
  },
  { translation: 'Avalanche', value: 'com.wavelink.android' },
  {
    translation: 'Invest Flex-Expert',
    value: 'com.flexthings.software.flexexpert'
  },
  {
    translation: 'Google Translate',
    value: 'com.google.android.apps.translate'
  },
  { translation: 'MPH Launcher', value: 'com.headset' },
  { translation: 'Cleaner', value: 'com.tofnatech.filecleaner' },
  { translation: 'Manifest Mobile', value: 'com.dairy.mobile.mm' },
  { translation: 'FM Web', value: 'com.fmi.fmwebintune2' },
  {
    translation: 'HALADJIAN Expert',
    value: 'teleportation.expert.haladjian'
  },
  { translation: 'App Test', value: 'teleportation.expert.test' },
  {
    translation: 'My Certificates',
    value: 'com.wesbunton.projects.mycertificates'
  },
  { translation: 'Zoom for Intune', value: 'us.zoom.videomeetings4intune' },
  { translation: 'Advansoft ODM', value: 'com.myadvansoft.odm' },
  { translation: 'Videospection', value: 'com.openvidu_react_native' },
  { translation: 'FlexDev', value: 'teleportation.expert.dev' },
  { translation: 'Giri Realwear', value: 'de.arexperts.giri.lite' },
  {
    translation: 'Speed Test Master Lite',
    value: 'com.atlasv.android.speedtest.lite'
  },
  { translation: 'Taskimo WORKS', value: 'com.taskimo.works.realwear' },
  {
    translation: 'International SOS Assistance',
    value: 'com.infostretch.iSOSAndroid'
  },
  {
    translation: 'Remote Expert latest application',
    value: 'com.dxc.rex.hmt.dev'
  },
  { translation: 'DSI', value: 'com.dsiglobal.mobileclient' },
  { translation: 'SpeachMe Studio', value: 'com.speachme.speachrecorder' },
  { translation: 'Eye4Stream', value: 'eu.headapp.eye4stream' },
  { translation: 'VocalRobot', value: 'com.vocalrobot.realwear' },
  { translation: 'W&H Visual Assistance', value: 'group.wh.visualassistance' },
  { translation: 'AXENS EMERGENCY', value: 'net.axens.emergency' },
  { translation: 'RFS By Davisware', value: 'dav.gerfs.xforms.android' },
  { translation: 'MartinSmartApp', value: 'com.martineng.sdm' },
  {
    translation: 'GembaCAM Streamer',
    value: 'com.android.example.camera2.basic'
  },
  { translation: 'REX WebApp', value: 'com.dxc.rex' },
  { translation: 'AnyDesk Equans', value: 'com.anydesk.anydeskandroid.custom' },
  { translation: 'ATOS PICOMTO', value: 'com.allianzes.atos' },
  { translation: 'Formulario', value: 'com.poc_form' },
  { translation: 'Dashboard', value: 'com.dashboard' },
  { translation: 'CGA', value: 'com.cgaappid' },
  {
    translation: 'To Do List App (TEST)',
    value: 'com.example.todolistapplication'
  },
  { translation: 'TF Workflow - JA', value: 'appinstall.xyz.teamforward' },
  { translation: 'DOCS-TR', value: 'com.taskimo.docs.realwear' },
  { translation: 'Hippo Connect', value: 'com.simplyvideo.hippo' },
  { translation: 'QuickBase Inc.', value: 'com.qbmobile' },
  { translation: 'Thin Manager ATMC Client', value: 'com.thinmanager.atmc' },
  { translation: 'FLNA AR Demo', value: 'com.FLNA.ARDemo' },
  { translation: 'WiFi Analyzer', value: 'com.vrem.wifianalyzer' },
  { translation: 'FacePro', value: 'io.faceapp' },
  { translation: 'Facepro', value: 'com.apkpremier.app' },
  { translation: 'Job Log', value: 'com.secondsightar.joblog' },
  { translation: 'RealwearOEM', value: 'com.airwatch.admin.awoem.vmware' },
  { translation: 'Zello', value: 'com.loudtalks' },
  { translation: 'Edge', value: 'com.wandoujia.phoenix2' },
  { translation: 'Citrix Files', value: 'com.citrix.sharefile' },
  {
    translation: 'Company-X Inspection',
    value: 'nz.co.peppercreative.inspection'
  },
  {
    translation: 'Bleam Connect',
    value: 'com.ubleam.customer.total.bleam.coupling.production'
  },
  { translation: 'DWS Demo', value: 'com.reactnativedemo' },
  { translation: 'Ubleam', value: 'com.ubleam.www.reader' },
  { translation: 'VRA Mobile', value: 'com.vuzix.vraconnect' },
  { translation: 'Intelatrac', value: 'com.aveva.intelatrac.mobile' },
  { translation: 'Extenden Maintenance', value: 'it.space1.maintenance.demo' },
  { translation: 'VIP Access', value: 'com.verisign.mvip.main' },
  {
    translation: 'chrome remote desktop',
    value: 'com.google.chromeremotedesktop'
  },
  { translation: 'First Audit', value: 'com.reinstil.firstaudit' },
  { translation: 'SGCclient', value: 'com.nttbiz.vcd.vidyotest' },
  { translation: 'STIM PRO', value: 'com.example.stimprolive' },
  { translation: '4 Remote', value: 'com.zerintia.remote.trials' },
  {
    translation: 'Tenova - Extended Collaboration - old',
    value: 'it.space1.virtualcollaboration.it.space1.tenovaiqspace1'
  },
  {
    translation: 'Android Device Policy',
    value: 'com.google.android.apps.work.clouddpc'
  },
  { translation: 'Datameter', value: 'com.nisargjhaveri.netspeed' },
  {
    translation: 'Data speed consumption',
    value: 'com.internet.speed.meter.lite'
  },
  { translation: 'meter link', value: 'com.extech.ExViewW' },
  { translation: 'Field Info', value: 'com.hitachisolutions.remotesupport_v3' },
  { translation: 'FacePro', value: 'com.softfoundry.facepro' },
  {
    translation: 'BuddyCamera RTSP Server',
    value: 'com.sciencearts.rtsp_server'
  },
  {
    translation: 'Tenova - Extended Maintenance',
    value: 'it.space1.maintenance'
  },
  { translation: 'kanepi phase 2 Cordova', value: 'com.kanepi.kanepi_cordova' },
  { translation: 'ESAB UCMon', value: 'com.esab.UCMon' },
  { translation: 'Google Lens', value: 'com.google.ar.lens' },
  {
    translation: 'Google Apps Device Policy',
    value: 'com.google.android.apps.enterprise.dmagent'
  },
  { translation: 'kanepi kwasabi demo', value: 'kanepi.kwms_demo' },
  { translation: 'Data Cube', value: 'com.softwareandprocessdesign.opgforms' },
  { translation: 'Secure Auth', value: 'secureauth.android.token' },
  { translation: 'ENBOS', value: 'com.example.enbos10' },
  { translation: 'RISE Dev', value: 'tech.rise.dev' },
  { translation: 'Cormant CS', value: 'com.cormant.cs' },
  { translation: 'Ease', value: 'com.easeinc.beacon' },
  { translation: 'Lookout for Work', value: 'com.lookout.enterprise' },
  { translation: 'Process Insights Go', value: 'tech.rise.lar' },
  { translation: 'Ario Connect', value: 'com.ario.connect' },
  {
    translation: 'NIQ Objekterkennung',
    value: 'org.tensorflow.lite.examples.detection'
  },
  { translation: 'EHS Insight', value: 'com.ehsinsight.mobile.alpine' },
  { translation: 'Sigmo', value: 'com.example.anibal.sigmo2' },
  { translation: 'Procore', value: 'com.procore.activities' },
  { translation: 'Vimeo', value: 'com.vimeo.android.videoapp' },
  { translation: 'DPC', value: 'com.afwsamples.testdpc' },
  { translation: 'Chromium', value: 'org.bromite.chromium' },
  { translation: 'Seek Thermal', value: 'com.tyriansystems.SeekThermal' },
  { translation: 'Ternio', value: 'com.ternioswitch' },
  { translation: 'Google Voice', value: 'com.google.android.apps.googlevoice' },
  { translation: 'QuickQ VPN', value: 'net.s17s.s17ray' },
  { translation: 'GrooVe IP VoIP Calls Text', value: 'com.snrblabs.grooveip' },
  {
    translation: 'Barcode to Excel',
    value: 'com.sukronmoh.bwi.barcodescanner'
  },
  { translation: 'VGIS', value: 'com.meemim.vgis' },
  { translation: '製品外観検査記録', value: 'com.nsw.patrolreport.debug' },
  { translation: 'NSW_IoT_Demo', value: 'net.giftarts.OSC_Client' },
  { translation: 'NSW_Manual_Demo', value: 'net.giftarts.RealWearDemo' },
  { translation: 'SmartWork', value: 'com.samwoo.smartwork_Realwear_V2' },
  {
    translation: 'SAP Mobile Services',
    value: 'com.sap.mobileservices.client'
  },
  { translation: 'Watermark', value: 'com.example.wattermark' },
  { translation: 'LinkedIn', value: 'com.linkedin.android' },
  { translation: 'MediaSync', value: 'com.example.MediaSync' },
  { translation: 'FIS', value: 'com.hitachisolutions.remotesupport_v4' },
  { translation: 'Data monitor', value: 'com.roysolberg.android.datacounter' },
  { translation: 'Ontario Scaling', value: 'ca.vdts.hmt.ontario_scaling' },
  {
    translation: 'Esterer Smart Service',
    value: 'com.adtance.esterersmartservice'
  },
  { translation: 'Adtance Platform Manager', value: 'com.adtance.apm' },
  {
    translation: 'Faun Visual Support',
    value: 'com.adtance.faunvisualsupport'
  },
  { translation: 'WuH Smart Service', value: 'com.adtance.wuhsmartservice' },
  {
    translation: 'iTranslate',
    value: 'com.sonicomobile.itranslatevoiceandroid'
  },
  {
    translation: 'Evonik Video Support',
    value: 'com.adtance.evoniksmartservice'
  },
  { translation: 'External Video Support', value: 'com.adtance.evonikevs' },
  {
    translation: 'FRIMO Smart Service',
    value: 'com.adtance.frimosmartservice'
  },
  { translation: 'FRIMO Platform Manager', value: 'com.adtance.fpm' },
  {
    translation: 'SPACE1 - Wearable Collaboration',
    value: 'it.space1.wearablecollaboration'
  },
  {
    translation: 'Topwerk Smart Service',
    value: 'com.adtance.topwerksmartservice'
  },
  { translation: 'Topwerk Add-on Wartung', value: 'com.topwerk.addonwartung' },
  { translation: 'Fugro Remote Expert Old', value: 'nl.vrowl.remoteexpert' },
  {
    translation: 'Messer Cutting Visual Service',
    value: 'com.messer.visualservice'
  },
  { translation: 'HYDAC Service', value: 'com.adtance.hydacservice' },
  { translation: 'MRPRINT', value: 'com.adtance.mrtechoptic' },
  { translation: 'Skype Lite', value: 'com.skype.m2' },
  { translation: 'Geo Reality', value: 'joe.georeality' },
  {
    translation: 'Navigation for Google Maps Go',
    value: 'com.google.android.apps.navlite'
  },
  { translation: 'Zoho Forms', value: 'com.zoho.forms.a' },
  { translation: 'FaceBook Messenger Lite', value: 'com.facebook.mlite' },
  { translation: 'Launcher', value: 'de.ubimax.android.launcher' },
  { translation: 'Top-Werk .Smartapp', value: 'com.topwerk.groupapp' },
  {
    translation: 'Kunzler Smart Service',
    value: 'com.adtance.kunzlersmartservice'
  },
  {
    translation: 'Baumann Smart Service',
    value: 'com.baumannautomation.smartservice'
  },
  {
    translation: 'Tuev Sued Verify',
    value: 'de.tuevsued.verifycertificate.mono'
  },
  { translation: 'Checklist', value: 'pl.ttpsc.ar.skillworxblades' },
  { translation: 'DELMIA Apriso', value: 'com.example.upc.app6_vestasapriso' },
  {
    translation: 'Vestas Training',
    value: 'pl.ttpsc.skillworx.skillworxlibrary'
  },
  { translation: 'MT Vitals', value: 'MTHeadsUp.MTHeadsUp' },
  {
    translation: 'Vestas Quality - Blades',
    value: 'pl.ttpsc.ar.vestas.qualitycheck'
  },
  { translation: 'Genzo', value: 'com.oneminuut.genzo' },
  {
    translation: 'DEV - Extended Field Solution',
    value: 'it.space1.virtualcollaboration.android.dev'
  },
  { translation: 'TSARA Vision', value: 'de.ubimax.bmw' },
  { translation: 'TopWerk .SmartVision', value: 'com.topwerk.smartvision' },
  { translation: 'DWI', value: 'pl.ttpsc.ar.skillworxvestas' },
  { translation: 'Dungs Service', value: 'com.adtance.dungsservice' },
  { translation: 'Italgas-Prod', value: 'it.space1.italgas.android' },
  { translation: 'Test WuH', value: 'com.adtance.support_test' },
  { translation: 'Messer CS Smart Service', value: 'com.messer.smartservice' },
  { translation: 'NP-ItalGas_noprod', value: 'it.space1.italgas.android.np' },
  { translation: 'PP_ItalGas_PreProd', value: 'it.space1.italgas.android.pp' },
  { translation: 'MT Profile', value: 'MTProfile.MTProfile' },
  { translation: 'MT Point', value: 'MTPoint.MTPoint' },
  { translation: 'QSpace1_Danieli_Prod', value: 'it.space1.qspace1' },
  {
    translation: 'Vestas Picking',
    value: 'pl.ttpsc.ar.vestas.warehousepicking'
  },
  { translation: 'Vuforia Tutorials', value: 'com.sikapps.vuforia' },
  {
    translation: 'CodeReadr Barcode Scanner',
    value: 'com.skycore.android.codereadr'
  },
  {
    translation: 'CodeReadr Scan To Field',
    value: 'com.codereadr.apps.scantofield'
  },
  { translation: 'Microsoft To Do', value: 'com.microsoft.todos' },
  { translation: 'Google Maps Go', value: 'com.google.android.apps.mapslite' },
  { translation: 'USB Camera Standard', value: 'infinitegra.app.usbcamera' },
  {
    translation: 'DEMO - Extended Field Solution',
    value: 'it.space1.virtualcollaboration.android.demo'
  },
  {
    translation: 'Version Tester',
    value: 'com.example.sebastianohm.versiontester'
  },
  { translation: 'MASA Service', value: 'com.adtance.masaservice' },
  { translation: 'Frech Eye', value: 'com.frech.eye' },
  { translation: 'MT STEM', value: 'MTInspection.MTInspection' },
  { translation: 'Leser VI', value: 'com.leser.vi' },
  { translation: 'Fugro Remote Expert', value: 'nl.vrowl.remoteexpert.fugro' },
  { translation: 'KM Smart Assist', value: 'de.oculavis.km.glass' },
  {
    translation: 'Mt Meter Readings',
    value: 'MTMeterReadings.MTMeterReadings'
  },
  {
    translation: 'Thermal Camera Plus',
    value: 'georg.com.thermal_camera_plus'
  },
  { translation: 'BOC Retail App', value: 'com.linde.bocmyagentmobile' },
  {
    translation: 'Microsoft Phone Companion',
    value: 'com.microsoft.appmanager'
  },
  { translation: 'Zoho Assist - Customer', value: 'com.zoho.assist.agent' },
  { translation: 'Google', value: 'com.google.android.googlequicksearchbox' },
  {
    translation: 'Remote Thermal Camera for FLIR',
    value: 'de.killig.remotethermalcam'
  },
  { translation: 'USB Camera', value: 'com.shenyaocn.android.usbcamera' },
  { translation: 'Autostart', value: 'com.autostart' },
  { translation: 'Grindr', value: 'com.grindrapp.android' },
  {
    translation: 'Kern Smart Service',
    value: 'com.kernmicrotechnik.smartservice'
  },
  {
    translation: 'Work Instructions',
    value: 'com.bondilabs.workinstructions.dev'
  },
  { translation: 'DeWalt_VirtualCollaboration', value: 'it.space1.dewalt' },
  { translation: 'Elixar Staging', value: 'com.bondilabs.elixar.stage.intl' },
  {
    translation: 'Movilizer',
    value: 'com.movilizer.client.android.app.or.test.dev'
  },
  { translation: 'KUHNE Remote Lens', value: 'com.kuhnegroup.remotelens' },
  { translation: 'Schäfer Assist', value: 'de.oculavis.schaefer.glass' },
  { translation: 'SLAM-TAGGING', value: 'pl.ttpsc.swxtag' },
  { translation: 'Schuler Connect', value: 'de.schuler.connect' },
  { translation: 'Fieldbit', value: 'com.fieldbit' },
  { translation: 'XpertView (Koerber)', value: 'de.oculavis.koerber.glass' },
  {
    translation: 'Oculavis SHARE Workflow',
    value: 'de.oculavis.workflows.share.glass'
  },
  { translation: 'Augmentir', value: 'com.augmentir.app' },
  { translation: 'HELP Library', value: 'au.com.ofigroup.helplibrary' },
  { translation: 'TopWerk SmartVision', value: 'com.topwerk.smartapp' },
  { translation: 'WiFi Overview', value: 'de.android.wifiscanner' },
  { translation: 'SEW Remote Assist', value: 'de.oculavis.sew.glass' },
  { translation: 'AssetCare Digitize', value: 'io.assetcare.digitize' },
  { translation: 'Kuhne Smart Service', value: 'com.kuhnegroup.smartservice' },
  { translation: 'FRIMO Smart Service v2', value: 'de.frimo.smartservice' },
  { translation: 'FAUN Visual Support', value: 'com.faun.visualsupport' },
  {
    translation: 'Reifenhäuser Visual Assistance',
    value: 'com.reifenhauser.visualassistance'
  },
  {
    translation: 'BlackBerry UEM Enroll',
    value: 'com.blackberry.nfcprovisioning'
  },
  {
    translation: 'Visual Assistance V2',
    value: 'group.wh.app.visualassistance'
  },
  { translation: 'TSARA Next Gen Test', value: 'de.ubimax.bmw.next' },
  { translation: 'HDI Remote', value: 'de.oculavis.hdi.glass' },
  { translation: 'SmarTECHS App', value: 'com.smartechsapp' },
  {
    translation: 'Teamviewer Universal Add-On',
    value: 'com.teamviewer.quicksupport.addon.universal'
  },
  {
    translation: 'GoKiosk',
    value: 'net.intricare.enterprisedevicekiosklockdown'
  },
  { translation: 'NICEinContact', value: 'com.dimira.cxone' },
  { translation: 'PingID', value: 'prod.com.pingidentity.pingid' },
  { translation: 'NGN rw PTW', value: 'com.NGN.ExcavationPermit' },
  { translation: 'Agility Test', value: 'com.ssginsight.agility' },
  { translation: 'Firefox Beta', value: 'org.mozilla.firefox_beta' },
  { translation: 'MFG', value: 'tw.com.kyec.mi.BUARSelect' },
  { translation: 'Chatbot Realwear Shell', value: 'com.Shell.TestAPI' },
  { translation: 'Training', value: 'com.test.RealwearSitewise' },
  {
    translation: 'AZ SCREEN RECORDER',
    value: 'com.hecorat.screenrecorder.free'
  },
  { translation: 'XR Sense Generic', value: 'com.xrtech.xrsense' },
  { translation: 'Digital Utsikt', value: 'com.bra' },
  { translation: 'Internet Speedtest', value: 'com.appseleration.speedtest' },
  {
    translation: 'ColorNote Notepad Test',
    value: 'com.socialnmobile.dictapps.notepad.color.note'
  },
  {
    translation: 'Circuit for Expert v8.1',
    value: 'com.unify.circuit.realwear.dev'
  },
  { translation: 'Circuit by Unify', value: 'com.unify.circuit' },
  { translation: 'Remote.Video', value: 'de.oculavis.voith.glass' },
  { translation: 'Calculator', value: 'com.realwear.calculator' },
  { translation: 'Clock', value: 'com.realwear.clock' },
  { translation: 'Pedometer', value: 'com.realwear.pedometer' },
  {
    translation: 'Passerelle Coach',
    value: 'io.supportsquare.passerelle.coach'
  },
  {
    translation: 'WIRTGENGROUP ExpertAssist',
    value: 'de.oculavis.wirtgengroup.glass'
  },
  { translation: 'Zips', value: 'com.zimperium.zips' },
  { translation: 'HINDSITE TEST', value: 'com.hindsiteind.kms.debug' },
  { translation: 'HINDSITE STAGING', value: 'com.hindsiteind.kms.staging' },
  { translation: 'ABB AFP', value: 'com.abb.FO4' },
  {
    translation: 'IBM Inspector Wearable',
    value: 'com.ibm.gbs.wearableinspector'
  },
  { translation: 'Voice Cells', value: 'ca.vdts.hmt.voicecells' },
  { translation: 'drony', value: 'org.sandroproxy.drony' },
  { translation: 'iX Connect for SASREF', value: 'com.Wipro.Connect' },
  { translation: 'xoi vision', value: 'io.xoi.vision' },
  {
    translation: 'Learning Library on Realwear',
    value: 'pl.ttpsc.skillworx.vestasll'
  },
  {
    translation: 'FlowInsight',
    value: 'com.knowledge_insight.workflow_executor_hmt'
  },
  { translation: 'Safetymint', value: 'com.safetymint' },
  { translation: 'Voice Excell', value: 'ca.vdts.hmt.voiceexcell' },
  { translation: 'uSee', value: 'com.televu.useee' },
  { translation: 'DigitalUtsikt', value: 'com.digitalutsikt' },
  { translation: 'Citrix Receiver', value: 'com.citrix.Receiver' },
  {
    translation: 'Remote Support by Pointmedia',
    value: 'com.pointmedia.remotesupport'
  },
  { translation: 'Collaudo Chivasso', value: 'eu.headapp.smartpicker' },
  {
    translation: 'ClearPass QuickConnect',
    value: 'com.arubanetworks.quickconnect.android'
  },
  { translation: 'My Zoom Application', value: 'us.zoom.pwa.twa' },
  { translation: 'ShipCheck POC', value: 'com.asalog.shipcheck' },
  { translation: 'EplanViewMobile', value: 'de.eplan.view.mobile' },
  { translation: 'Augmenticon SHARE', value: 'de.oculavis.augmenticon.glass' },
  { translation: 'Zscaler Client Connector', value: 'zscaler.com.zscaler' },
  { translation: 'Safety Inspection', value: 'mew.purrr.safety_inspection' },
  {
    translation: 'Toku Test App',
    value: 'atsuo.tokuyama.sample.audiorecordtest'
  },
  { translation: 'Insight Mobile', value: 'de.proglove.connect' },
  { translation: 'ROK Extractor', value: 'com.teamviewer.vendortestapp' },
  {
    translation: 'HHIGROUP REMOTE',
    value: 'com.virnect.remote.realwear2_onpremise_hhigroup'
  },
  { translation: 'Golf GPS Scorecard by SwingU', value: 'com.swingbyswing' },
  {
    translation: 'Hole19 Golf GPS Scorecard',
    value: 'com.hole19golf.hole19.beta'
  },
  { translation: 'AIRT', value: 'com.weatherford.myadvisor' },
  { translation: 'Space1', value: 'it.space1.ac.demo' },
  {
    translation: 'Schunk Remote Support',
    value: 'com.schunkgroup.remotesupport.app'
  },
  { translation: 'ABUS LiveService', value: 'com.abus.liveservice.app' },
  { translation: 'Vuforia Offline', value: 'com.schillig.androidwebserver' },
  { translation: 'TTS Voiceover Test', value: 'com.realwear.ttsservice' },
  {
    translation: 'Brigid Training',
    value: 'px.mw.android.clinician.clinical.demo.production'
  },
  { translation: 'MediaSync1650', value: 'com.example.MediaSync165' },
  { translation: 'MediaSync1652', value: 'com.example.MediaSync1652' },
  { translation: 'Spacedesk', value: 'ph.spacedesk.beta' },
  { translation: 'Fuze', value: 'com.fuze.fuzeapp' },
  { translation: 'Elixar Dev', value: 'com.bondilabs.elixar.dev.intl' },
  { translation: 'HT7045', value: 'com.kyec.MRTK' },
  { translation: 'Zoom by RealWear', value: 'com.realwear.zoom' },
  {
    translation: 'Google Home',
    value: 'com.google.android.apps.chromecast.app'
  },
  { translation: 'PICOMTO Sandbox Application', value: 'com.allianzes.psa' },
  { translation: 'Work Assistant', value: 'com.nulitics.workassistant' },
  { translation: 'Safety Mint', value: 'com.safetymint_hmt' },
  { translation: 'Universal', value: 'com.microsoft.intune.aospagent' },
  { translation: 'Videotool', value: 'com.videotool.app.videotoolsecure' },
  { translation: 'WalkDown', value: 'com.emersonprocess.in.epm.walkdown' },
  { translation: 'UpKeep', value: 'com.onupkeep' },
  { translation: 'Holo One Sphere', value: 'com.holoone.sphere' },
  { translation: 'BasicNote', value: 'notizen.basic.notes.notas.note.notepad' },
  { translation: 'Notes', value: 'com.eco.note' },
  { translation: 'dynamic 365', value: 'com.microsoft.crm.crmphone' },
  { translation: 'WhiteNote', value: 'notizen.white.notes.notas.note.notepad' },
  { translation: 'HELLO WORLD', value: 'com.example.appdegraseras' },
  { translation: 'Smart Note', value: 'com.e9foreverfs.note' },
  {
    translation: 'SkillworxGW for Starshot Taiwan',
    value: 'pl.ttpsc.skillworx.guidedworker'
  },
  {
    translation: 'Visual Asstistance',
    value: 'de.frommsystem.visualassistance'
  },
  { translation: 'ZoomRoom', value: 'com.example.zoomrooms' },
  { translation: 'Poka', value: 'io.poka.pwa.twa' },
  { translation: 'AIRe Link demo', value: 'link.aire.client' },
  { translation: 'wifi-analyzer', value: 'abdelrahman.wifianalyzerpro' },
  { translation: 'XomSupport', value: 'com.example.xomsupport' },
  { translation: 'XomSupport2.0', value: 'com.its.xomsupport' },
  { translation: 'Parsable- Test', value: 'com.wi.director' },
  { translation: 'Xalt Mobility', value: 'com.cvreactnative.xna' },
  { translation: 'VideoSoft', value: 'com.videosoft.android.encoder' },
  { translation: 'WifiMAN', value: 'com.ubnt.usurvey' },
  { translation: 'vistafinder', value: 'jp.kddilabs.VistaFinder.VFP2P' },
  { translation: 'kaspersky', value: 'com.kaspersky.kes.ja' },
  { translation: 'What3Words', value: 'com.what3words.android' },
  { translation: 'AIRe Link v2', value: 'link.aire.client.realwear' },
  { translation: 'ABS - QSPACE1', value: 'it.overit.mobile.qspace1' },
  { translation: 'Teste', value: 'com.tandeminnovation.epal.onpocket' },
  {
    translation: 'Nearshore Video Client',
    value: 'com.videosoft.android.viewernearshore'
  },
  {
    translation: 'Nearshore Encoder',
    value: 'com.videosoft.android.encodernearshore'
  },
  { translation: 'Ring Central', value: 'com.glip.mobile' },
  { translation: 'Knowtified AR', value: 'com.Knowtified.ARRealwear' },
  {
    translation: 'solusight',
    value: 'org.chromium.webapk.ace4ad479c47dc71f_v2'
  },
  {
    translation: 'demo solusight',
    value: 'org.chromium.webapk.acce68ebe26337370_v2'
  },
  {
    translation: 'Glencore Planned Task Observation',
    value: 'mew.purrr.planned_task_observation'
  },
  { translation: 'Borescope', value: 'com.endoscope.camera' },
  { translation: 'OTG View 2', value: 'com.dingtai.snakecamera' },
  {
    translation: 'Exdescope App',
    value: 'us.endoscopeappforandroid.endoscopecamera.endoscopeappforandroid.endoscopecamera'
  },
  { translation: 'OpenVPN', value: 'de.blinkt.openvpn' },
  { translation: 'SIAD', value: 'ru.itorum.remotehelper.siad' },
  { translation: 'MS-SSTP-VPN', value: 'com.dzebb.sstp' },
  { translation: 'Aruba VIA VPN', value: 'com.aruba.via' },
  { translation: 'strongSwan VPN', value: 'org.strongswan.android' },
  { translation: 'PD Eye Remote Assist', value: 'xyz.appinstall.pdi' },
  { translation: 'Kolus', value: 'com.kolusrealwear' },
  { translation: 'EKO Health', value: 'com.ekodevices.android' },
  { translation: 'RescueLens', value: 'com.logmein.rescuelens' },
  { translation: 'Sophos', value: 'com.sophos.smsec' },
  { translation: 'Idaho Scaling', value: 'ca.vdts.hmt.idaho_scaling' },
  { translation: 'Battery Logger', value: 'kr.hwangti.batterylog' },
  { translation: 'Wireless@SGx', value: 'sg.gov.imda.wsgapp2_android' },
  { translation: 'Kolus', value: 'com.kolus.glasses' },
  { translation: 'Zalo-Test-22.04.01', value: 'com.zing.zalo' },
  { translation: 'QR Barcode Scanner - ScannedIT', value: 'com.gamma.scan' },
  { translation: 'Scandit Barcode', value: 'com.scandit.demoapp' },
  { translation: 'Mav-Wear', value: 'uk.co.mavreality.video' },
  { translation: 'CGI Maeva', value: 'com.cgi.maevasupport.car' },
  { translation: 'CGI Maeva Bremen', value: 'com.cgi.maevasupport' },
  { translation: 'Clima APK', value: 'com.DefaultCompany.ClimaApp' },
  { translation: 'muneco', value: 'com.DefaultCompany.lab12' },
  { translation: 'SPALO2.3', value: 'jp.spalo.studio.spalo.assistant' },
  { translation: 'RazVisionWR', value: 'infinitegra.razvisionwr' },
  { translation: 'OPTAC-X', value: 'xyz.appinstall.optac' },
  { translation: 'RWIN', value: 'com.asalog.rwin' },
  { translation: 'HFIR Test', value: 'com.movilizer.client.android.hfir.ril' },
  { translation: 'BuiltView Mobile', value: 'com.builtview.mobile.dev' },
  { translation: 'CamOn', value: 'com.spynet.camon' },
  { translation: 'Logis 4 Test', value: 'at.dataphone.logisandroid.template' },
  { translation: 'Solusight Test', value: 'com.tripsdoc.rtcmulti' },
  { translation: 'Mobile SSH', value: 'mobileSSH.feng.gao' },
  { translation: 'JuiceSSH', value: 'com.sonelli.juicessh' },
  { translation: 'Klabra', value: 'com.klabra.klabra_app' },
  { translation: 'Trial Assessment', value: 'ca.vdts.hmt.arm' },
  { translation: 'Voice Select', value: 'ca.vdts.hmt.data_gathering' },

  { translation: 'My Programs', value: 'com.realwear.launcher.ApplicationActivity' }
];
