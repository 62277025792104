import { APP_INITIALIZER, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { AuthModule as OidcAuthModule, OidcConfigService, OidcSecurityService } from 'angular-auth-oidc-client';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OID_CONFIG } from './config/oidc-token.config';
import { oidcAuthConfig } from './config/oidc-auth.config';
import { AuthGuard } from './guards/auth.guard';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { OpenIdConfiguration } from 'angular-auth-oidc-client/lib/config/openid-configuration';
import { SuperAdminGuard } from './guards/super-admin.guard';
import { AuthAndImpersonationGuard } from './guards/auth-and-impersonation.guard';
import { CanaryService } from './canary.service';

@NgModule({
  imports: [OidcAuthModule.forRoot()]
})
export class SharedFeatureAuthModule {
  static forRoot(openIdConfiguration: OpenIdConfiguration): ModuleWithProviders<SharedFeatureAuthModule> {
    // Forced Config
    openIdConfiguration = {
      ...openIdConfiguration,
      triggerAuthorizationResultEvent: true
    };

    return {
      ngModule: SharedFeatureAuthModule,
      providers: [
        { provide: OID_CONFIG, useValue: openIdConfiguration },

        // Services
        CanaryService,

        // Guards
        AuthGuard,
        AuthAndImpersonationGuard,
        SuperAdminGuard,

        // Interceptors,
        TokenInterceptor,

        {
          provide: APP_INITIALIZER,
          useFactory: oidcAuthConfig,
          deps: [OidcConfigService, OID_CONFIG, OidcSecurityService],
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          deps: [OidcSecurityService, Injector],
          multi: true
        }
      ]
    };
  }
}
