export const categoryNames = [
  'featured',
  'remoteassist',
  'realwear',
  'workflow',
  'training',
  'inspection',
  'ai',
  'all'
];
