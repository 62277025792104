import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  apiUrl: string;

  constructor(private oidcSecurityService: OidcSecurityService, private injector: Injector) {
    this.apiUrl = injector.get('API_URL');
  }

  get userInfoEndpoint(): string | undefined {
    return this.oidcSecurityService.configuration.wellknown?.userinfoEndpoint;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith('/v1')) {
      req = req.clone({
        url: this.apiUrl + req.url
      });
    }

    if (!req.url.startsWith(this.apiUrl) && req.url !== this.userInfoEndpoint) {
      return next.handle(req);
    }

    const newReqOptions: { setHeaders: { [id: string]: string } } = {
      setHeaders: {
        authorization: 'Bearer ' + this.oidcSecurityService.getToken()
      }
    };

    const selectedWorkspace = sessionStorage.getItem('workspace');
    if (selectedWorkspace?.length && !req.url.startsWith(this.apiUrl + '/v1/profile')) {
      newReqOptions.setHeaders['x-realwear-workspace'] = selectedWorkspace;
    }

    const clone = req.clone(newReqOptions);

    return next.handle(clone);
  }
}
