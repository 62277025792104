import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, filter, firstValueFrom, map, Observable, shareReplay, switchMap } from 'rxjs';
import { openReleaseNotesDialog } from '../../dialogs/release-notes-dialog/release-notes-dialog.component';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightToArc, faStar } from '@fortawesome/pro-solid-svg-icons';
import { datadogRum } from '@datadog/browser-rum';
import { MarketplaceAuth } from '../../auth/marketplace-auth.service';
import { AppService } from '../../data-access/services/app.service';
import { MarketplaceStore } from '../../data-access/store/marketplace.store';

@Component({
  selector: 'realwear-cloud-app-detail',
  templateUrl: './app-detail.component.html',
  styleUrls: ['./app-detail.component.scss'],
  preserveWhitespaces: true
})
export class AppDetailComponent implements AfterViewInit {
  readonly appId$ = new BehaviorSubject<string>('');

  readonly appVideo$: Observable<string>;

  readonly addIcon = faPlus;
  readonly starIcon = faStar;
  readonly loginIcon = faArrowRightToArc;

  readonly categories$: Observable<string[]>;
  readonly description$: Observable<string>;
  readonly title$: Observable<string>;
  readonly author$: Observable<string>;
  readonly headline$: Observable<string>;
  readonly versionName$: Observable<string>;
  readonly versionCode$: Observable<string | number>;
  readonly shouldCollapseDescription$: Observable<boolean>;
  readonly releaseDate$: Observable<Date | string>;
  readonly imgSrc$: Observable<string>;
  readonly releaseNotes$: Observable<string>;
  readonly screenshotUrls$: Observable<string[]>;
  readonly supportContact$: Observable<string>;
  readonly supportLink$: Observable<string>;
  readonly documentationLink$: Observable<string>;
  readonly privacyPolicyLink$: Observable<string>;
  readonly termsLink$: Observable<string>;
  readonly languages$: Observable<string>;
  readonly devices$: Observable<string>;
  readonly isCertified$: Observable<boolean>;
  readonly isPro$: Observable<boolean>;
  readonly isNonInstallable$: Observable<boolean>;

  readonly packageName$: Observable<string>;

  readonly supportContactHref$: Observable<string>;

  readonly isAuthenticated$: Observable<boolean>;

  @Input()
  set appId(appId: string | null) {
    if (!appId) {
      return;
    }
    this.appId$.next(appId);
  }

  get appId(): string {
    return this.appId$.value;
  }

  @Input() showDemoButton = true;

  @Output() readonly loginRequested = new EventEmitter();

  @ViewChild('toggleElement', { static: false }) toggleElement: ElementRef | undefined;

  constructor(
    public authService: MarketplaceAuth,
    apps: AppService,
    private store: MarketplaceStore,
    private matDialog: MatDialog,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.isAuthenticated$ = authService.isAuthenticated$;

    const fullApp$ = this.appId$.pipe(
      filter((appId) => !!appId),
      switchMap((appId) => apps.fetchFullApp(appId)),
      shareReplay(1)
    );

    this.appVideo$ = fullApp$.pipe(map((j) => j.appVideo));
    this.packageName$ = fullApp$.pipe(map((j) => j.id));
    this.categories$ = fullApp$.pipe(map((j) => j.categories));
    this.description$ = fullApp$.pipe(map((j) => j.description));
    this.title$ = fullApp$.pipe(map((j) => j.title));
    this.author$ = fullApp$.pipe(map((j) => j.author));
    this.headline$ = fullApp$.pipe(map((j) => j.headline));
    this.versionCode$ = fullApp$.pipe(map((j) => j.versionCode));
    this.versionName$ = fullApp$.pipe(map((j) => j.versionName));
    this.releaseDate$ = fullApp$.pipe(map((j) => j.releaseDate));
    this.imgSrc$ = fullApp$.pipe(map((j) => j.imgSrc));
    this.releaseNotes$ = fullApp$.pipe(map((j) => j.releaseNotes));
    this.screenshotUrls$ = fullApp$.pipe(map((j) => j.screenshotUrls));
    this.isCertified$ = fullApp$.pipe(map((j) => (j.certified || false) && !j.pro));
    this.isPro$ = fullApp$.pipe(map((j) => j.pro || false));

    this.isNonInstallable$ = fullApp$.pipe(map((j) => j.nonInstallable));

    this.supportContact$ = fullApp$.pipe(map((j) => ensureLink(j.supportContact)));
    this.supportLink$ = fullApp$.pipe(map((j) => ensureLink(j.supportLink)));
    this.documentationLink$ = fullApp$.pipe(map((j) => ensureLink(j.documentationLink)));
    this.termsLink$ = fullApp$.pipe(map((j) => ensureLink(j.termsLink)));
    this.privacyPolicyLink$ = fullApp$.pipe(map((j) => ensureLink(j.privacyPolicyLink)));
    this.supportContactHref$ = this.supportContact$.pipe(
      map((j) => (j?.includes('@') ? 'mailto:' + j : ensureLink(j)))
    );

    this.languages$ = fullApp$.pipe(
      map((j) => {
        const displayNames = new Intl.DisplayNames(['en'], { type: 'language' });
        return j.languages
          .map((k) => {
            try {
              return displayNames.of(k);
            } catch {
              return k;
            }
          })
          .join(', ');
      })
    );

    this.devices$ = fullApp$.pipe(map((j) => j.devices?.join(', ') || ''));

    this.shouldCollapseDescription$ = this.description$.pipe(map((j) => j?.length > 200));
  }

  collapseDescription = true;

  async onOpenReleaseNotes(): Promise<void> {
    datadogRum.addAction('OpenReleaseNotes');

    openReleaseNotesDialog(this.matDialog, await firstValueFrom(this.releaseNotes$));
  }

  async onRequestDemo(): Promise<void> {
    datadogRum.addAction('StartRequestDemo');

    this.store.updateState({ demoRequested: { packageName: await firstValueFrom(this.packageName$) } });
  }

  onInstallRequested() {
    datadogRum.addAction('Start Install from detail page');

    this.store.updateState({ installRequested: { appId: this.appId$.value } });
  }

  onSelectCategory(category: string) {
    this.store.updateState({ categoryRequested: category });
  }

  ngAfterViewInit() {
    // just for the 'read more' button: if the app is displayed on a mat-card, add a class to the button

    if (this.el.nativeElement.parentElement.tagName.toLowerCase() === 'mat-card' && this.toggleElement) {
      this.renderer.addClass(this.toggleElement.nativeElement, 'on-mat-card');
    }
  }
}

function ensureLink(value: string): string {
  if (!value?.length) {
    return value;
  }

  if (value?.includes('@')) {
    return value;
  }

  if (!value?.toLocaleLowerCase()?.startsWith('http')) {
    return 'https://' + value;
  }

  return value;
}
