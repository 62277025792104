import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedUtilsPipesModule } from '@realwear-cloud/shared/utils-pipes';

import { ApkUploaderComponent } from './apk-uploader.component';

@NgModule({
  imports: [CommonModule, SharedUtilsPipesModule, MatButtonModule, MatProgressBarModule],
  exports: [ApkUploaderComponent],
  declarations: [ApkUploaderComponent]
})
export class ApkUploaderModule {}
