import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './login.guard';

const routes = [
  {
    path: '',
    loadChildren: () =>
      import('@realwear-cloud/app-marketplace/feature-app-marketplace').then(
        (m) => m.AppMarketplaceFeatureAppMarketplaceModule
      ),
    canActivate: [LoginGuard]
  }
] as Routes;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
