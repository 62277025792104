import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class MarketplaceAuth {
  readonly isAuthenticated$: Observable<boolean>;
  readonly isOperator$: Observable<boolean>;

  constructor() {
    this.isAuthenticated$ = of(true);
    this.isOperator$ = of(false);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  authorizeForAppId(appId: string) {
    // Not Implemented
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  silentAuthorizeForAppId(appId: string) {
    // Not Implemented
  }
}
