import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { datadogRum } from '@datadog/browser-rum';
import { forkJoinBatch } from '@realwear-cloud/shared/utils-helpers';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  defer,
  filter,
  firstValueFrom,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
  throwError
} from 'rxjs';
import { Device, DeviceGroup } from '../../types/devices';
import {
  AppAssignmentModel,
  AppWithAssignment,
  Assignment,
  MarketplaceApp,
  MarketplaceFullApp,
  marketplaceAppKeys,
  shuffle
} from '../../types/marketplace-app';
import { MarketplaceStore } from '../store/marketplace.store';

@Injectable()
export class AppService {
  readonly _fetchLegacyCatalog$: Observable<AppCatalogResponse>;

  readonly _fetchCatalog$: Observable<MarketplaceCatalogResponse>;

  readonly _fetchFeatured$: Observable<MarketplaceFeaturedResponse>;

  readonly _fetchDevices$: Observable<DevicesResponse>;
  readonly _fetchGroups$: Observable<DeviceGroupsResponse>;

  constructor(private httpClient: HttpClient, private transferState: TransferState, private store: MarketplaceStore) {
    this._fetchDevices$ = httpClient.get<DevicesResponse>('/v1/devices?limit=50').pipe(shareReplay(1));
    this._fetchGroups$ = httpClient.get<DeviceGroupsResponse>('/v1/devicegroups').pipe(shareReplay(1));

    this._fetchLegacyCatalog$ = httpClient.get<AppCatalogResponse>('/v1/apps/public').pipe(shareReplay(1));

    this._fetchCatalog$ = httpClient
      .get<MarketplaceCatalogResponse>('https://rwcloudappmarketplace.blob.core.windows.net/json/compiled/prod.json')
      .pipe(shareReplay(1));

    this._fetchFeatured$ = httpClient
      .get<MarketplaceFeaturedResponse>(
        `https://rwcloudappmarketplace.blob.core.windows.net/json/compiled/featured.json`
      )
      .pipe(shareReplay(1));
  }

  /**
   * Fetches the basic metadata for any apps within a specified category
   * @param categoryName
   * @param limit
   * @returns
   */
  appsForCategory(categoryName: string, limit?: number): Observable<MarketplaceApp[]> {
    return this._fetchCatalog$.pipe(
      map((j) => {
        if (categoryName === 'certified') {
          return j.filter((k) => k.certified === true);
        }
        if (categoryName === 'all') {
          return j.sort((a, b) => a.title.localeCompare(b.title));
        }

        return j.filter((k) => k.categories.includes(categoryName)).sort((a, b) => a.title.localeCompare(b.title));
      }),

      map((j) => j.slice(0, limit || 100))
    );
  }

  /**
   * Fetches the basic metadata for the featured apps and their slots
   * @returns
   */
  getFeaturedApps(): Observable<FeaturedApp[]> {
    return this._fetchFeatured$.pipe(catchError(() => of([] as MarketplaceFeaturedResponse)));
  }

  getSubFeaturedApps(category: string, limit: number): Observable<MarketplaceApp[]> {
    return this._fetchCatalog$.pipe(
      map((j) => {
        shuffle(j);
        return j;
      }),
      map((j) => j.filter((k) => k.categories.includes(category))),
      map((j) => j.slice(0, limit))
    );
  }

  /**
   * Fetches the basic metadata for the apps and makes sure the app ID is used instead of package name
   * @returns
   */
  getMarketPlaceAppsWithRealId(): Observable<MarketplaceApp[]> {
    return this._fetchCatalog$.pipe(
      switchMap((fullApps) => {
        const appObservables = fullApps.map((fullApp) => {
          const marketplaceApp = extractProperties<MarketplaceApp, MarketplaceCatalogResponseItem>(
            fullApp,
            marketplaceAppKeys
          );
          const realId$ = from(this.appIdForPackageName(marketplaceApp.id));
          return realId$.pipe(map((realId) => ({ ...marketplaceApp, id: realId ? realId : marketplaceApp.id })));
        });
        return appObservables.length > 0 ? forkJoin(appObservables) : of([]);
      })
    );
  }

  /**
   * Fetches the full marketplace metadata for an application
   * @param appIdOrPackageName The AppId or PackageName to query
   * @returns
   */
  fetchFullApp(appIdOrPackageName: string): Observable<MarketplaceFullApp> {
    const packageName$ = appIdOrPackageName.includes('.')
      ? of(appIdOrPackageName)
      : from(this.packageNameForAppId(appIdOrPackageName));

    return combineLatest([this._fetchCatalog$, packageName$]).pipe(
      filter(([, packageName]) => !!packageName),
      map(([catalog, packageName]) => {
        return catalog.find((k) => k.id === packageName);
      }),
      switchMap((catalogItem) => {
        return combineLatest([of(catalogItem), this.detailsForPackageName(catalogItem?.id || '')]);
      }),
      switchMap(([metadata, details]) => {
        if (!metadata || !details) {
          return EMPTY;
        }

        return of({ ...metadata, ...details });
      })
    );
  }

  /**
   * Fetches the id of assignments of a specific object (group or device)
   * @param objectId$ observable to objectId$
   * @returns
   */
  getObjectAssignments(objectId$: Observable<string>): Observable<Assignment[]> {
    return combineLatest([
      objectId$,
      this.store.select((state) => state.appAssignmentsChanged).pipe(startWith(true))
    ]).pipe(
      filter(([, appAssignmentsChanged]) => appAssignmentsChanged),
      switchMap(([objectId]) => {
        return this.httpClient.get<{ assignments: AppAssignmentModel[] }>('/v1/appassignment/' + objectId).pipe(
          mergeMap((response) => {
            this.store.updateState({ appAssignmentsChanged: false });
            if (response.assignments.length === 0) {
              return of([]);
            }
            // else add packageNames
            return forkJoin(
              response.assignments.map(async (assignment) => {
                const packageName = await this.packageNameForAppId(assignment.appId);
                return {
                  ...assignment,
                  packageName: packageName || ''
                };
              })
            );
          })
        );
      })
    );
  }

  /**
   * Adds Marketplace Catalog data to a set of assigned apps
   * @returns
   * @param assignments$
   * @param apps$
   */
  addAppsToAssignments(
    assignments$: Observable<Assignment[]>,
    apps$: Observable<MarketplaceApp[]>
  ): Observable<AppWithAssignment[]> {
    return combineLatest([apps$, assignments$]).pipe(
      map(([allApps, assignedApps]) =>
        assignedApps.map((assignedApp) => {
          const matchingApp = allApps.find((app) => app.id === assignedApp.appId || app.id === assignedApp.packageName);

          if (matchingApp) {
            return {
              id: matchingApp.id,
              title: matchingApp.title,
              author: matchingApp.author,
              imgSrc: matchingApp.imgSrc,
              certified: matchingApp.certified,
              pro: matchingApp.pro,
              installed: true,
              mode: 'installed'
            };
          } else {
            return {
              id: assignedApp.appId,
              title: assignedApp.appId,
              author: '',
              imgSrc: '',
              installed: true,
              mode: 'installed'
            };
          }
        })
      )
    );
  }

  /**
   * Adds assignment to a larger collection of apps/ catalog data / enterprise data
   * Mode 'shop' is used because in a shop all apps are available, yet a few are already installed
   * @param apps$
   * @param assignments$: observable to assignments
   * @returns
   */

  addAssignmentsToApps(
    apps$: Observable<MarketplaceApp[]>,
    assignments$: Observable<Assignment[]>
  ): Observable<AppWithAssignment[]> {
    return combineLatest([apps$, assignments$]).pipe(
      map(([allApps, assignedApps]) => {
        return allApps.map((app) => {
          const matchingApp = assignedApps.find(
            (assignedApp) => app.id === assignedApp.appId || app.id === assignedApp.packageName
          );

          const appToReturn: Partial<AppWithAssignment> = app;
          if (matchingApp) {
            appToReturn.installed = true;
            appToReturn.mode = 'shop';
          } else {
            appToReturn.installed = false;
            appToReturn.mode = 'shop';
          }
          return appToReturn as AppWithAssignment;
        });
      })
    );
  }

  private detailsForPackageName(packageName: string): Observable<MarketplaceAppDetails> {
    const stateKey = makeStateKey<MarketplaceAppDetails>('packageDetails: ' + packageName);

    if (this.transferState.hasKey(stateKey)) {
      const details = this.transferState.get(stateKey, null);

      if (details) {
        return of(details);
      }
    }

    return this.httpClient
      .get<MarketplaceAppDetails>(
        `https://rwcloudappmarketplace.blob.core.windows.net/json/compiled/${packageName}_details.json`
      )
      .pipe(
        catchError(() => {
          return of({
            description: 'No Description',
            releaseNotes: 'No Release Notes'
          } as MarketplaceAppDetails);
        }),
        tap((details) => this.transferState.set(stateKey, details)),
        shareReplay(1)
      );
  }

  /**
   * Resolves the app id for the supplied package name (internally uses the legacy catalog)
   */
  async appIdForPackageName(packageName: string): Promise<string | null> {
    const catalog = await firstValueFrom(this._fetchLegacyCatalog$);

    const matchingApps = catalog.applications.filter(
      (j) => j.packageName?.toLocaleLowerCase() === packageName?.toLocaleLowerCase()
    );

    return matchingApps.length > 0 ? matchingApps[0]?.appId : null;
  }

  /**
   * Resolves the package name for the app id (internally uses the legacy catalog)
   */
  private async packageNameForAppId(appId: string): Promise<string | null> {
    const catalog = await firstValueFrom(this._fetchLegacyCatalog$);
    return (
      catalog.applications.find((j) => j.appId?.toLocaleLowerCase() === appId?.toLocaleLowerCase())?.packageName || null
    );
  }

  /**
   * Fetches the id of assignments for the specified app id or package Name
   * @param appIdOrPackageName The AppId to query
   * @returns
   */
  assignmentsForApp(appIdOrPackageName: string): Observable<string[]> {
    const appId$ = from(this.appIdForPackageName(appIdOrPackageName)).pipe(map((j) => j || appIdOrPackageName));

    return combineLatest([this.getDevices(), this.getGroups(), appId$]).pipe(
      map(
        ([devices, groups, appId]) =>
          [[...devices.map((j) => j.id), ...groups.map((j) => j.id)] as string[], appId] as [string[], string]
      ),
      switchMap(([j, appId]) => {
        const assignmentResults = j.map((k) => this.getAssignmentRequest(k, appId));

        return forkJoinBatch(assignmentResults, 10);
      }),
      map((j) => j.filter((k) => !!k) as string[])
    );
  }

  private getAssignmentRequest(objectId: string, appId: string): Observable<string | null> {
    // Might need to convert the appId (if it's a package name) to an actual app id
    const inferredAppId$ = from(this.appIdForPackageName(appId)).pipe(map((j) => j || appId));

    return inferredAppId$.pipe(
      switchMap((inferredAppId) =>
        this.httpClient
          .get(`/v1/appassignment/${objectId}/${inferredAppId}`, { observe: 'response' })
          .pipe(catchError(() => of(false)))
      ),
      map((j) => {
        if (j instanceof HttpResponse) {
          return objectId;
        }

        return null;
      })
    );
  }

  private createAssignmentRequest(objectId: string, appId: string) {
    return defer(() =>
      this.httpClient.post(`/v1/appassignment/${objectId}/${appId}`, {}, { observe: 'response' })
    ).pipe(catchError((error: HttpErrorResponse) => of(error)));
  }

  /**
   * Assigns the
   * @param appId The AppId to assign an object to
   * @param objectIds The object ids to assign to the app
   * @returns
   */
  assignApp(appId: string, objectIds: string[]) {
    return from(this.appIdForPackageName(appId)).pipe(
      map((inferredAppId) => inferredAppId || appId),
      switchMap((inferredAppId) => {
        // still a package name? throw an error
        if (inferredAppId.includes('.')) {
          datadogRum.addError('could not find app id for package name ' + inferredAppId);
          return throwError(() => new Error("can't find appId"));
        }

        const batch = objectIds.map((o) => this.createAssignmentRequest(o, inferredAppId));

        return forkJoinBatch(batch);
      })
    );
  }

  /**
   * Unassigns the
   * @param appId The AppId to unassign an object to
   * @param objectId: device or group id to unassign
   * @returns
   */
  unAssignApp(appId: string, objectId: string): Promise<void> {
    return firstValueFrom(
      from(this.appIdForPackageName(appId)).pipe(
        map((inferredAppId) => inferredAppId || appId),
        switchMap((inferredAppId) => {
          // still a package name? throw an error
          if (inferredAppId.includes('.')) {
            datadogRum.addError('could not find app id for package name ' + inferredAppId);
            return throwError(() => new Error("can't find appId"));
          }
          return this.httpClient.delete<void>(`/v1/appassignment/${objectId}/${appId}`);
        })
      )
    );
  }

  /**
   * Gets the number of devices within this current workspace
   */
  getDeviceCount(): Observable<number> {
    return this._fetchDevices$.pipe(map((j) => j.total));
  }

  getDevices(): Observable<Device[]> {
    return this._fetchDevices$.pipe(
      map((j) => {
        return j.devices.map((d) => ({
          id: d.id,
          name: d.name,
          serialNumber: d.serialNumber
        }));
      })
    );
  }

  getGroups(): Observable<DeviceGroup[]> {
    return this._fetchGroups$.pipe(
      map((j) =>
        j.deviceGroups.map((g) => ({
          id: g.deviceGroupId,
          name: g.name
        }))
      )
    );
  }
}

export interface FeaturedApp {
  slot: string | number;
  imageUrl: string;
  title: string;
  href: string;
}

interface MarketplaceAppDetails {
  description: string;
  releaseNotes: string;
}

export type MarketplaceCatalogResponseItem = Omit<MarketplaceFullApp, 'description' | 'releaseNotes'>;
type MarketplaceCatalogResponse = MarketplaceCatalogResponseItem[];

type MarketplaceFeaturedResponse = FeaturedApp[];

interface DevicesResponse {
  total: number;
  devices: { id: string; serialNumber: string; name: string }[];
}

interface DeviceGroupsResponse {
  deviceGroups: { deviceGroupId: string; name: string }[];
}

interface AppCatalogResponse {
  applications: {
    appId: string;
    packageName: string;
  }[];
}

function extractProperties<T, U>(extended: U, keys: string[]) {
  return keys.reduce((pickedObj, key) => {
    pickedObj[key as keyof typeof pickedObj] = extended[key as keyof typeof extended];
    return pickedObj;
  }, {} as { [K in (typeof keys)[number]]: (typeof extended)[keyof typeof extended] }) as unknown as T;
}
