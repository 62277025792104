import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { NotificationContainerComponent } from './notification-container/notification-container.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PopupNotificationManager } from './popup-notification-manager';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [NotificationContainerComponent],
  imports: [
    CommonModule,
    OverlayModule,
    MatProgressSpinnerModule,
    FontAwesomeModule,
    MatButtonModule,
    MatProgressBarModule
  ]
})
export class PopupNotificationModule {
  static forRoot(): ModuleWithProviders<PopupNotificationModule> {
    return {
      ngModule: PopupNotificationModule,
      providers: [PopupNotificationManager]
    };
  }
}
