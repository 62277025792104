import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { datadogRum } from '@datadog/browser-rum';
import { faCircleInfo, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { PopupNotificationManager } from '@realwear-cloud/shared/ui-kit';
import { Observable, defer, delay, first, firstValueFrom, iif, map, of, shareReplay, switchMap, tap } from 'rxjs';
import { AppService } from '../../data-access/services/app.service';
import { Device, DeviceGroup } from '../../types/devices';

@Component({
  selector: 'realwear-cloud-install-app-dialog',
  templateUrl: './install-app-dialog.component.html',
  styleUrls: ['./install-app-dialog.component.scss']
})
export class InstallAppDialogComponent implements OnInit {
  readonly installIcon = faPlus;
  readonly circleInfoIcon = faCircleInfo;

  readonly reachedDeviceLimit$: Observable<boolean>;
  readonly devices$: Observable<Device[]>;
  readonly groups$: Observable<DeviceGroup[]>;

  readonly appName$: Observable<string>;

  private _checkedDevices: string[] = [];
  private _assignedDevices: string[] = [];

  private _allDeviceIds: string[] = [];
  private _allGroupIds: string[] = [];

  hasGroups = true;
  hasDevices = true;
  isInternal = false;

  currentMode: 'Groups' | 'Devices' = 'Groups';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private appId: string,
    private appService: AppService,
    private matDialogRef: MatDialogRef<unknown>,
    private popupNotifications: PopupNotificationManager
  ) {
    const currentUrl = window.location.href;
    if (currentUrl.includes('marketplace')) {
      this.isInternal = true;
    } else {
      this.isInternal = false;
    }

    this.reachedDeviceLimit$ = appService.getDeviceCount().pipe(
      tap((j) => (this.hasDevices = !!j)),
      map((j) => j > 50),
      shareReplay(1)
    );

    this.devices$ = this.reachedDeviceLimit$.pipe(
      delay(0),
      switchMap((j) =>
        iif(
          () => j,
          of([]),
          defer(() => appService.getDevices())
        )
      ),
      tap((j) => (this._allDeviceIds = j.map((k) => k.id))),
      shareReplay(1)
    );

    this.groups$ = this.appService.getGroups().pipe(
      delay(0),
      tap((j) => (this._allGroupIds = j.map((k) => k.id))),
      shareReplay(1)
    );

    this.appName$ = appService.fetchFullApp(appId).pipe(
      map((j) => j?.title || 'Unknown'),
      shareReplay(1)
    );
  }

  ngOnInit(): void {
    this.groups$
      .pipe(
        first(),
        map((j) => !!j?.length)
      )
      .subscribe((hasGroups) => {
        this.currentMode = hasGroups ? 'Groups' : 'Devices';
        this.hasGroups = hasGroups;
      });

    this.appService
      .assignmentsForApp(this.appId)
      .pipe(first())
      .subscribe((assignments) => {
        this._assignedDevices = assignments;
      });
  }

  changeMode(newMode: 'Groups' | 'Devices') {
    this.currentMode = newMode;
    this._checkedDevices = [];
  }

  toggleCheckedDevice(deviceOrGroup: { id: string }, value: boolean) {
    if (this.fetchAssigned(deviceOrGroup)) {
      return;
    }

    const index = this._checkedDevices.indexOf(deviceOrGroup.id);

    if (index > -1 && !value) {
      this._checkedDevices.splice(index, 1);
      return;
    }

    if (value && index === -1) {
      this._checkedDevices.push(deviceOrGroup.id);
    }
  }

  fetchAssigned(deviceOfGroup: { id: string }) {
    return this._assignedDevices.includes(deviceOfGroup?.id);
  }

  fetchChecked(deviceOrGroup: { id: string }) {
    return this._checkedDevices.includes(deviceOrGroup?.id);
  }

  get canInstall(): boolean {
    return !!this._checkedDevices.length;
  }

  get isPartiallySelected(): boolean {
    if (!this._checkedDevices.length) {
      return false;
    }

    return !this.isFullyChecked;
  }

  get isFullyChecked(): boolean {
    if (!this._checkedDevices.length) {
      return false;
    }

    const items = this.currentMode === 'Devices' ? this._allDeviceIds : this._allGroupIds;
    return !items.find((j) => !this._checkedDevices.includes(j) && !this._assignedDevices.includes(j));
  }

  toggleCheckAll(change: { checked: boolean }) {
    this._checkedDevices = [];

    if (!change.checked) {
      return;
    }

    const items = this.currentMode === 'Devices' ? this._allDeviceIds : this._allGroupIds;

    items.forEach((j) => {
      if (this.fetchAssigned({ id: j }) || this._checkedDevices.includes(j)) {
        return;
      }

      this._checkedDevices.push(j);
    });
  }

  async onInstallApp(): Promise<void> {
    if (!this.canInstall) {
      return;
    }

    datadogRum.addAction('install_app', { app_id: this.appId });

    const notification = this.popupNotifications.addNotification({
      text: 'Installing Application...',
      uniqueKey: 'installation', // unique key will be added to data-testid, which allows us to query in E2E tests
      state: 'loading'
    });

    try {
      await firstValueFrom(this.appService.assignApp(this.appId, this._checkedDevices));
      notification.updateConfig({
        text: 'Application Installed',
        state: 'success'
      });
    } catch (error) {
      notification.updateConfig({
        text: 'Failed to Install Application',
        state: 'failed'
      });
    }

    this.matDialogRef.close();
  }
}
