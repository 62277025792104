<div class="row" [class.internal]="scrollable">
  <span *ngIf="showHeader" data-testid="category-header">{{ title$ | async }}</span>
  <div *ngIf="showSearch" class="search-field">
    <fa-icon class="search-icon" [icon]="faSearch"></fa-icon>
    <input
      #searchInput
      data-testid="search-box"
      class="search-box"
      type="search"
      results="0"
      placeholder="Find an app"
      [formControl]="searchControl" />
  </div>
</div>
<realwear-cloud-app-grid
  [apps]="(apps$ | async) || []"
  [state]="state$ | async"
  (refreshRequested)="retryEvent$.next(true)"
  [scrollable]="scrollable">
</realwear-cloud-app-grid>
