import { Component } from '@angular/core';
import { faCheck, faCircleInfo, faTriangleExclamation, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { PopupNotificationRef } from '../popup-notification-ref';

@Component({
  selector: 'realwear-cloud-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss']
})
export class NotificationContainerComponent {
  readonly faInfo = faCircleInfo;
  readonly faCheck = faCheck;
  readonly faWarning = faTriangleExclamation;
  readonly faClose = faXmark;

  attachments: PopupNotificationRef[] = [];

  onRemove(notification: PopupNotificationRef) {
    notification.remove();
  }

  onUndo(notification: PopupNotificationRef) {
    if (!notification.config?.undoFunction) {
      return;
    }

    notification.config.undoFunction();
  }
}
