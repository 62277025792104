import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AppService } from '../data-access/services/app.service';

@Injectable()
export class CheckAppExistsGuard implements CanActivate {
  constructor(private apps: AppService, private router: Router, private location: Location) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const appId = route.params['id'];

    try {
      const foundApp = await firstValueFrom(this.apps.fetchFullApp(appId));

      if (foundApp) {
        return true;
      }
    } catch {
      // Ignore
    }

    // Navigate to the 404 page
    await this.router.navigate(['/404']);

    // Replace the url so it looks like the app
    this.location.replaceState(state.url);

    return true;
  }
}
