import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { faCheck, faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { MarketplaceStore } from '../../../data-access/store/marketplace.store';
import { BehaviorSubject } from 'rxjs';
import { MarketplaceAuth } from '../../../auth/marketplace-auth.service';

@Component({
  selector: 'realwear-cloud-app-link',
  templateUrl: './app-link.component.html',
  styleUrls: ['./app-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLinkComponent {
  @Input() appId = '';
  @Input() appName?: string;
  @Input() author?: string;
  @Input() imgSrc?: string;

  @Input() certified?: boolean = false;
  @Input() pro?: boolean = false;

  @Input() installed?: boolean | null = null;
  @Input() mode: string | undefined = undefined;

  @HostBinding('class.loading') get getLoadingClass() {
    return this.loading;
  }

  @Input() loading?: boolean = false;

  @Output() refreshRequested = new EventEmitter();

  readonly faCheck = faCheck;
  readonly faXmark = faXmark;
  readonly faPlus = faPlus;
  changingAssignments$ = new BehaviorSubject<boolean>(false);

  constructor(private store: MarketplaceStore, public authService: MarketplaceAuth) {}

  onInstall() {
    if (this.appId) {
      this.store.updateState({ installRequested: { appId: this.appId } });
    }
    this.refreshRequested.emit();

    this.changingAssignments$.next(true);
    // once the installation is successful, the page is reloaded, and the spinner on the button will disappear
    // when not successful however, the spinner will disappear after a few seconds
    setTimeout(() => {
      this.changingAssignments$.next(false);
    }, 1000);
  }

  onUnInstall() {
    if (this.appId) {
      this.store.updateState({ unInstallRequested: { appId: this.appId } });
    }
    this.refreshRequested.emit();
    this.changingAssignments$.next(true);
    setTimeout(() => {
      this.changingAssignments$.next(false);
    }, 1000);
    // once the assignments are changed, the whole page will be refreshed and the spinner on the button will disappear
  }

  onButtonClick(event: MouseEvent) {
    event.stopPropagation(); // prevent button event from bubbling up to the clickable area of app-link
  }

  onButtonHover(event: MouseEvent) {
    event.stopPropagation(); // prevent button event from bubbling up to the clickable area of app-link
  }
}
