import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[realwearCloudBackgroundImage]'
})
export class BackgroundImageDirective {
  @Input('realwearCloudBackgroundImage') set bgSrc(val: string) {
    this.renderer2.setStyle(this.element.nativeElement, 'background-image', `url('${val}')`);
  }

  constructor(private element: ElementRef<HTMLElement>, private renderer2: Renderer2) {}
}
