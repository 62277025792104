<h2 data-testid="page-header">Featured</h2>

<div class="featured">
  <video class="hero slot" #myvideo loop autoplay playsinline>
    <source src="https://rwcloudappmarketplace.blob.core.windows.net/featuredapps/h264_360.mp4" type="video/mp4" />
  </video>

  <a
    *ngIf="slotSmall1$ | async as slot"
    class="slot side1"
    [routerLink]="slot.href"
    [attr.title]="slot.title"
    [realwearCloudBackgroundImage]="slot.imageUrl">
  </a>

  <a
    *ngIf="slotSmall2$ | async as slot"
    class="slot side2"
    [routerLink]="slot.href"
    [attr.title]="slot.title"
    [realwearCloudBackgroundImage]="slot.imageUrl">
  </a>
</div>

<div class="certified-apps" *ngIf="certifiedAppCount > 0">
  <div class="certified-app-category">
    <header>
      <h2 data-testid="certified-apps-header">Certified by RealWear</h2>
      <p>Certified applications adhere to our guidelines for a great hands-free experience</p>
    </header>
    <a [routerLink]="fetchAppUrl(app)" class="app" *ngFor="let app of certifiedApps">
      <realwear-cloud-app-link
        [appName]="app.title"
        [author]="app.author"
        [imgSrc]="app.imgSrc"
        [certified]="app.certified"
        [pro]="app.pro">
      </realwear-cloud-app-link>
    </a>
    <a class="viewall" routerLink="../certified" *ngIf="certifiedAppCount > 6">View all Certified</a>
  </div>
</div>

<div class="apps" *ngIf="!!!(isLoading$ | async); else loading">
  <div class="app-category remote-assistance">
    <h2>Remote Assistance</h2>
    <a [routerLink]="fetchAppUrl(app)" class="app" *ngFor="let app of appsRemoteAssistance$ | async | slice : 0 : 3">
      <realwear-cloud-app-link
        class="clickable"
        [appId]="app.id"
        [appName]="app.title"
        [author]="app.author"
        [imgSrc]="app.imgSrc"
        [certified]="app.certified"
        [pro]="app.pro">
      </realwear-cloud-app-link>
    </a>
    <a class="viewall" routerLink="../remoteassist">View all Remote Assistance</a>
  </div>

  <div class="app-category workflow">
    <h2>Workflow</h2>
    <a [routerLink]="fetchAppUrl(app)" class="app" *ngFor="let app of appsWorkflow$ | async | slice : 0 : 3">
      <realwear-cloud-app-link
        class="clickable"
        [appId]="app.id"
        [appName]="app.title"
        [author]="app.author"
        [imgSrc]="app.imgSrc"
        [certified]="app.certified"
        [pro]="app.pro">
      </realwear-cloud-app-link>
    </a>
    <a class="viewall" routerLink="../workflow">View all Workflow</a>
  </div>
</div>

<ng-template #loading>
  <div class="apps">
    <div class="app-category remote-assistance">
      <h2>Remote Assistance</h2>
      <ng-template [ngTemplateOutlet]="loadingApp"></ng-template>
      <ng-template [ngTemplateOutlet]="loadingApp"></ng-template>
      <ng-template [ngTemplateOutlet]="loadingApp"></ng-template>
    </div>
    <div class="app-category workflow">
      <h2>Workflow</h2>
      <ng-template [ngTemplateOutlet]="loadingApp"></ng-template>
      <ng-template [ngTemplateOutlet]="loadingApp"></ng-template>
      <ng-template [ngTemplateOutlet]="loadingApp"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #loadingApp>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
</ng-template>
