<div class="images">
  <div
    *ngFor="let screenshot of screenshotUrls$ | async; let index = index"
    class="image image{{ index + 1 }}"
    [realwearCloudBackgroundImage]="screenshot"></div>
</div>

<mat-divider class="divider-1"></mat-divider>

<dl class="information">
  <dt>Supported Languages</dt>
  <dd data-testid="languages" class="languages">{{ languages$ | async }}</dd>

  <dt>Supported Devices</dt>
  <dd data-testid="devices" class="devices">{{ devices$ | async }}</dd>
</dl>

<mat-divider class="divider-2"></mat-divider>
<dl class="links">
  <ng-container *ngIf="(supportContactHref$ | async)?.length">
    <dt>Support Contact</dt>
    <dd>
      <a [href]="supportContactHref$ | async" target="_blank">{{ supportContact$ | async | removeHttp }}</a>
    </dd>
  </ng-container>

  <ng-container *ngIf="(supportLink$ | async)?.length">
    <dt>Support</dt>
    <dd>
      <a [href]="supportLink$ | async" target="_blank">{{ supportLink$ | async | removeHttp }}</a>
    </dd>
  </ng-container>

  <ng-container *ngIf="(documentationLink$ | async)?.length">
    <dt>Documentation</dt>
    <dd>
      <a [href]="documentationLink$ | async" target="_blank">{{ documentationLink$ | async | removeHttp }}</a>
    </dd>
  </ng-container>

  <ng-container *ngIf="(termsLink$ | async)?.length">
    <dt>Terms of use</dt>
    <dd>
      <a [href]="termsLink$ | async" target="_blank">{{ termsLink$ | async | removeHttp }}</a>
    </dd>
  </ng-container>

  <ng-container *ngIf="(privacyPolicyLink$ | async)?.length">
    <dt>Privacy Policy</dt>
    <dd>
      <a [href]="privacyPolicyLink$ | async" target="_blank">{{ privacyPolicyLink$ | async | removeHttp }}</a>
    </dd>
  </ng-container>
</dl>
<ng-container *ngIf="appVideo$ | async as appVideo">
  <realwear-cloud-app-video [videoUrl]="appVideo$ | async" class="showcase"></realwear-cloud-app-video>
</ng-container>
<div class="metadata">
  <h2 class="app-title" data-testid="title">{{ title$ | async }}</h2>

  <div class="lockup">
    <img class="icon" [src]="imgSrc$ | async" />

    <div class="author" data-testid="author">by {{ author$ | async }}</div>

    <div class="version">
      v{{ versionName$ | async }} ({{ releaseDate$ | async | date : 'MMM yyy' }})
      <a (click)="onOpenReleaseNotes()">release notes</a>
    </div>
  </div>

  <div class="certified" *ngIf="isCertified$ | async">
    <img class="certified" src="assets/certified_rw.svg" />

    <span>Certified by RealWear</span>
  </div>

  <div class="pro" *ngIf="isPro$ | async">
    <img class="pro" src="assets/pro_rw.svg" />

    <span>Pro Account Required</span>
  </div>

  <div class="nav-buttons">
    <nav>
      <a
        class="nav-button"
        data-testid="category"
        mat-ripple
        (click)="onSelectCategory(category)"
        routerLinkActive="selected"
        *ngFor="let category of categories$ | async">
        {{ category | categoryName }}
      </a>
    </nav>
  </div>

  <div class="subtitle" *ngIf="headline$ | async">
    {{ headline$ | async }}
  </div>

  <div class="description" [class.collapsable]="shouldCollapseDescription$ | async" [class.hide]="collapseDescription">
    <p>{{ description$ | async }}</p>

    <div class="toggle" #toggleElement>
      <span>...</span>
      <a [routerLink]="" (click)="collapseDescription = false"> read more </a>
    </div>
  </div>

  <ng-container *ngIf="!((isNonInstallable$ | async) || false); else notInstallable">
    <realwear-cloud-marketplace-button
      data-testid="install"
      *ngIf="(isAuthenticated$ | async) && (authService.isOperator$ | async) === false"
      [icon]="addIcon"
      value="Install {{ title$ | async }}"
      (click)="onInstallRequested()"></realwear-cloud-marketplace-button>
    <realwear-cloud-marketplace-button
      data-testid="login"
      *ngIf="(isAuthenticated$ | async) !== true"
      actionStyle="primary"
      value="Login to Install"
      [icon]="loginIcon"
      (click)="loginRequested.emit()"></realwear-cloud-marketplace-button>
  </ng-container>

  <ng-template #notInstallable>
    <realwear-cloud-marketplace-button
      data-testid="not-available"
      value="Not Available"
      [disabled]="true"></realwear-cloud-marketplace-button>
  </ng-template>

  <div class="demo" *ngIf="showDemoButton">
    <strong>Ready to explore?</strong>

    <p>No RealWear device or Cloud account? No worries! Speak to an expert to get started.</p>

    <realwear-cloud-marketplace-button
      data-testid="demo"
      value="Speak to an expert"
      actionStyle="secondary"
      [icon]="starIcon"
      (click)="onRequestDemo()"></realwear-cloud-marketplace-button>
  </div>
</div>
