<div class="apps" *ngIf="isShowingApps">
  <ng-container *ngFor="let app of apps; let i = index">
    <ng-container *ngIf="!!app.imgSrc">
      <a (click)="onAppDetailRequested(app.id)">
        <realwear-cloud-app-link
          class="clickable"
          [appId]="app.id"
          [appName]="app.title"
          [author]="app.author"
          [imgSrc]="app.imgSrc"
          [certified]="app.certified"
          [pro]="app.pro"
          [attr.data-testid]="'app-link-' + app.id"
          [mode]="isAppWithAssignment(app) ? app.mode : undefined"
          [installed]="isAppWithAssignment(app) ? app.installed : false"
          (refreshRequested)="refreshRequested.emit()">
        </realwear-cloud-app-link>
      </a>
    </ng-container>

    <ng-container *ngIf="!app.imgSrc">
      <div>
        <realwear-cloud-app-link
          [appId]="app.id"
          [appName]="app.title"
          [author]="app.author"
          [imgSrc]="app.imgSrc"
          [certified]="app.certified"
          [pro]="app.pro"
          [mode]="isAppWithAssignment(app) ? app.mode : undefined"
          [installed]="isAppWithAssignment(app) ? app.installed : null"
          (refreshRequested)="refreshRequested.emit()">
        </realwear-cloud-app-link>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="noapps" *ngIf="hasNoApps">
  <realwear-cloud-nothing-here [message]="noAppsMessage" [isEnterprise]="isEnterprise"></realwear-cloud-nothing-here>
</div>

<div class="apps loading" *ngIf="state === 'loading'">
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
  <a>
    <realwear-cloud-app-link [loading]="true"></realwear-cloud-app-link>
  </a>
</div>

<div class="error" *ngIf="state === 'error'">
  <realwear-cloud-nothing-here></realwear-cloud-nothing-here>
  <p>Sorry, something went wrong</p>
  <button data-testid="error" mat-stroked-button (click)="refreshRequested.emit()">Please Try Again</button>
</div>
