import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MaterialModule } from '../material/material.module';
import { BaseComponent } from './base/base.component';
import { BusyButtonComponent } from './busy-button/busy-button.component';
import { ApkUploaderModule } from './apk-uploader';
import { DateFilterModule } from './date-filter';
import { DateRangePickerModule } from './date-range-picker';
import { PremiumBadgeComponent } from './premium-badge/premium-badge.component';
import { ProBadgeDirective } from './pro-badge.directive';
import { RwcTableModule } from './rwc-table/rwc-table.module';
import { ChoiceSelectorComponent } from './choice-selector/choice-selector.component';
import { InfinitePipe } from './workspace-status/infinite.pipe';
import { WorkspaceStatusComponent } from './workspace-status/workspace-status.component';
import { NothingHereComponent } from './nothing-here/nothing-here.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const badges = [PremiumBadgeComponent];
const componentsToBeExported = [
  ChoiceSelectorComponent,
  ProBadgeDirective,
  WorkspaceStatusComponent,
  BaseComponent,
  BusyButtonComponent,
  NothingHereComponent
];

@NgModule({
  declarations: [...componentsToBeExported, ...badges, InfinitePipe],
  imports: [
    CommonModule,
    MaterialModule,
    RwcTableModule,
    ReactiveFormsModule,
    DateFilterModule,
    DateRangePickerModule,
    MatProgressSpinnerModule,
    ApkUploaderModule,
    FontAwesomeModule
  ],
  exports: [
    MaterialModule,
    RwcTableModule,
    ...componentsToBeExported,
    ...badges,
    DateFilterModule,
    DateRangePickerModule,
    ApkUploaderModule
  ]
})
export class ComponentsModule {}
