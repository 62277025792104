import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from './components/components.module';
import { MaterialModule } from './material/material.module';

const uiKitModules = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, MaterialModule, ComponentsModule];

/**
 * @deprecated individually import the relevant modules per module
 *
 * @export
 * @class SharedUiKitModule
 */
@NgModule({
  imports: [...uiKitModules],
  exports: [...uiKitModules]
})
export class SharedUiKitModule {}
