import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHttp'
})
export class RemoveHttpPipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (!value) {
      return value;
    }

    // If value is not a string, return value
    if (typeof value !== 'string') {
      return value;
    }

    let strValue: string = value;

    if (!strValue?.length) {
      return strValue;
    }

    if (strValue.startsWith('http://')) {
      strValue = strValue.substring(7);
    }

    if (strValue.startsWith('https://')) {
      strValue = strValue.substring(8);
    }

    if (strValue.startsWith('www.')) {
      strValue = strValue.substring(4);
    }

    return strValue;
  }
}
