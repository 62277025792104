import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class LoginGuard implements CanActivate {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(
    private authService: OidcSecurityService,
    private router: Router,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    //
  }

  async canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!isPlatformBrowser(this.platformId)) {
      return true;
    }

    // 404 is fine, we can ignore that
    if (state?.url === '/404') {
      sessionStorage.removeItem('redirect_app_id');
      return true;
    }

    await firstValueFrom(this.authService.checkAuth());

    const appId = sessionStorage.getItem('redirect_app_id');

    if (appId) {
      sessionStorage.removeItem('redirect_app_id');

      await this.router.navigateByUrl('/app/' + appId);

      return false;
    }

    return true;
  }
}
