import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedUtilsPipesModule } from '@realwear-cloud/shared/utils-pipes';
import { RwcColumnButtonComponent } from './rwc-column-button';
import { RwcColumnCheckboxComponent } from './rwc-column-checkbox';
import { RwcColumnHostDirective } from './rwc-column-chooser';
import { RwcColumnDateComponent } from './rwc-column-date';
import { RwcColumnDateTimeComponent } from './rwc-column-dateTime';
import { RwcColumnFirmwareComponent } from './rwc-column-firmware';
import { RwcColumnIconComponent } from './rwc-column-icon';
import { RwcColumnLinkComponent } from './rwc-column-link';
import { RwcColumnRelativeDateComponent } from './rwc-column-relativeDate';
import { RwcColumnTextComponent } from './rwc-column-text';

@NgModule({
  id: 'RwcTableColumns',
  imports: [CommonModule, SharedUtilsPipesModule, MatButtonModule, MatTooltipModule, MatCheckboxModule, MatIconModule],
  declarations: [
    RwcColumnHostDirective,
    RwcColumnTextComponent,
    RwcColumnRelativeDateComponent,
    RwcColumnDateTimeComponent,
    RwcColumnDateComponent,
    RwcColumnFirmwareComponent,
    RwcColumnButtonComponent,
    RwcColumnLinkComponent,
    RwcColumnCheckboxComponent,
    RwcColumnIconComponent
  ],
  exports: [RwcColumnHostDirective]
})
export class RwcTableColumnsModule {}
