import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FilterWidgetPopupComponent } from './filter-widget-popup/filter-widget-popup.component';
import { RWCTDatePickerComponent } from './rwctdate-picker/rwctdate-picker.component';
import { FilterWidgetComponent } from './filter-widget/filter-widget.component';
import { RWCTItemSelectorComponent } from './rwctitem-selector/rwctitem-selector.component';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { FilterWidgetGroupComponent } from './filter-widget-group/filter-widget-group.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatSelectModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CdkTreeModule,
    MatIconModule,
    MatDividerModule,
    CdkScrollableModule,
    MatCheckboxModule,
    ScrollingModule,
    MatInputModule
  ],
  declarations: [
    FilterWidgetComponent,
    FilterWidgetPopupComponent,
    RWCTDatePickerComponent,
    RWCTItemSelectorComponent,
    FilterWidgetGroupComponent
  ],
  exports: [FilterWidgetGroupComponent]
})
export class RwcTableFilterModule {}
