export interface FirmwareVersionModel {
  displayName: string;
  model: string;
}

/**
 * Check if string is not empty
 */
export const nonEmpty = /.*\S.*/;

/**
 * NAV500
 *
 * Decodes the device model from a given firmware version
 */
export const T21G = /^.+C\.NAV5XX\.G$/;
/**
 * NAVIGATOR Z1
 *
 * Decodes the device model from a given firmware version
 */
export const T21S = /^.+[TC][-.]NAVIGATOR-Z1\.S$/;
/**
 * HMT-1
 *
 * Decodes the device model from a given firmware version
 */
export const T1200G = /^.+2-\d+-C.HMT-1.G(-HW)?$/;
/**
 * HMT-1
 *
 * Decodes the device model from a given firmware version
 */
export const T1100G = /^.+0-\d+-C.HMT-1.G(-HW)?|(HMT-1\.G.+)/;
/**
 * HMT-1Z1
 *
 * Decodes the device model from a given firmware version
 */
export const T1100S = /^(.+C\.HMT-1Z1\.S(-HW)?)|(HMT-1\.S.+)$/;

export const isHMTDevice = new RegExp(`(${T1200G.source})|(${T1100G.source})|(${T1100S.source})`);

export function getDeviceModelFromFirmwareVersionString(value: string): FirmwareVersionModel {
  const input = value.trim();

  if (T21G.exec(input)) {
    return { displayName: 'NAV500', model: 'T21G' };
  } else if (T21S.exec(input)) {
    return { displayName: 'NAVIGATOR-Z1', model: 'T21S' };
  } else if (T1200G.exec(input)) {
    return { displayName: 'HMT-1', model: 'T1200G' };
  } else if (T1100G.exec(input)) {
    return { displayName: 'HMT-1', model: 'T1100G' };
  } else if (T1100S.exec(input)) {
    return { displayName: 'HMT-1Z1', model: 'T1100S' };
  }

  throw new Error('Failed To Find Matching Device');
}

export const isUrl = /(https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/;

// This is the standard email validator built in to Angular
export const isEmail =
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const isStrongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const isName = /^[a-zA-Z- ]+$/;

export const isValidWorkspaceName = /^[a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9]|[a-zA-Z0-9]*)$/;

export const isSerialNumber = (search: string): boolean => {
  const SerialNumberRegex = /^(MP|[DEP][123])[1-9][G-Z][1-9A-C][0-3][0-9][DN][0-9][0-9]{6}$/;
  const SerialNumberArcherRegex = /^(MP|[DEP][123])[AB][1-9][G-Z][1-9A-C][0-3][0-9][DN][0-9][0-9]{6}$/;

  if (!search?.length) {
    return false;
  }

  if (SerialNumberRegex.test(search)) {
    return true;
  }

  return SerialNumberArcherRegex.test(search);
};
