import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface MarketplaceState {
  appDetailRequested: string;
  categoryRequested: string;
  installRequested: { appId: string } | null;
  unInstallRequested: { appId: string } | null;
  demoRequested: { packageName: string } | null;
  pageNotFound: boolean;
  objectId: string;
  appAssignmentsChanged: boolean;
}

const initialState: MarketplaceState = {
  appDetailRequested: '',
  categoryRequested: '',
  demoRequested: null,
  installRequested: null,
  unInstallRequested: null,
  pageNotFound: false,
  objectId: '',
  appAssignmentsChanged: false
};

@Injectable()
export class MarketplaceStore {
  private _state$: BehaviorSubject<MarketplaceState>;

  constructor() {
    this._state$ = new BehaviorSubject<MarketplaceState>(initialState);
  }

  updateState(state: Partial<MarketplaceState>): MarketplaceState {
    this._state$.next({ ...this._state$.value, ...state });
    return this._state$.getValue();
  }

  get state$(): Observable<MarketplaceState> {
    return this._state$.asObservable();
  }

  select<K>(selector: (state: MarketplaceState) => K): Observable<K> {
    return this.state$.pipe(map(selector), distinctUntilChanged());
  }
}
