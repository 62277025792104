<ng-container *ngIf="!loading; else blank">
  <img data-testid="app-link-img" class="app-link-img" [src]="imgSrc ? imgSrc : 'assets/images/enterprise-app.png'" />
  <div data-testid="app-link-title" class="app-link-title" [ngClass]="[certified ? 'certified' : '']">
    {{ appName }}
  </div>
  <div data-testid="app-link-author" class="app-link-author">
    <span [ngClass]="[!!author ? '' : 'hidden']">by </span>{{ author }}
  </div>

  <div class="badges" *ngIf="pro || certified">
    <img
      data-testid="certified"
      src="assets/certified_rw.svg"
      *ngIf="certified && !pro"
      title="Certified by RealWear" />
    <img data-testid="pro" src="assets/pro_rw.svg" *ngIf="pro" title="Pro App" />
  </div>

  <ng-container>
    <button
      *ngIf="mode === 'shop' && installed"
      data-testid="app-link-button-installed"
      class="installed"
      matTooltip="This app is installed. Go to 'Installed' to uninstall."
      matTooltipPosition="above">
      <fa-icon [icon]="faCheck" class="icon"></fa-icon>
      Installed
    </button>

    <button
      *ngIf="mode === 'shop' && !installed && (authService.isOperator$ | async) === false"
      (click)="onButtonClick($event); onInstall()"
      (mouseover)="onButtonHover($event)"
      data-testid="app-link-button-install"
      class="install"
      matTooltip="Click to install"
      matTooltipPosition="above">
      <fa-icon *ngIf="(changingAssignments$ | async) === false" [icon]="faPlus" class="icon"></fa-icon>
      <div class="spin-wrapper" data-testid="app-link-button-install-spinner" *ngIf="changingAssignments$ | async">
        <mat-spinner diameter="16"></mat-spinner>
      </div>
      Install
    </button>
    <button
      *ngIf="mode === 'installed' && installed && (authService.isOperator$ | async) === false"
      (click)="onButtonClick($event); onUnInstall()"
      (mouseover)="onButtonHover($event)"
      data-testid="app-link-button-uninstall"
      class="uninstall"
      matTooltip="Click to uninstall"
      matTooltipPosition="above">
      <fa-icon *ngIf="(changingAssignments$ | async) === false" [icon]="faXmark" class="icon"></fa-icon>
      <div class="spin-wrapper" data-testid="app-link-button-uninstall-spinner" *ngIf="changingAssignments$ | async">
        <mat-spinner diameter="16"></mat-spinner>
      </div>
      Uninstall
    </button>
  </ng-container>
</ng-container>

<ng-template #blank>
  <div data-testid="app-link-img" class="app-link-img">&nbsp;</div>
  <div data-testid="app-link-title" class="app-link-title">&nbsp;</div>
  <div data-testid="app-link-author" class="app-link-author">&nbsp;</div>
</ng-template>
