// define a class first, so we instantiate, extract the keys,
// then use the keys to filter properties at runtime.
class MarketplaceAppClass {
  id = '';
  title = '';
  author = '';
  imgSrc = '';
  certified = false;
  pro = false;
}

export interface MarketplaceApp extends Omit<MarketplaceAppClass, 'certified' | 'pro'> {
  certified?: boolean;
  pro?: boolean;
}

export const marketplaceAppKeys = Object.keys(new MarketplaceAppClass());

export interface MarketplaceFullApp extends MarketplaceApp {
  headline: string;

  description: string;

  categories: string[];

  releaseDate: Date | string;

  nonInstallable: boolean;

  versionName: string;
  versionCode: string | number;

  screenshotUrls: string[];

  releaseNotes: string;

  supportContact: string;
  supportLink: string;
  documentationLink: string;
  termsLink: string;
  privacyPolicyLink: string;

  languages: string[];

  devices: string[];
  appVideo: string;
}

export type InstalledStatus = 'stable' | 'prerelease' | undefined;

export interface AppWithAssignment extends MarketplaceApp {
  installed: boolean;
  mode: string;
}

export interface AppAssignmentModel {
  appId: string;
  prerelease: boolean;
}

export interface Assignment extends AppAssignmentModel {
  packageName: string;
}

export function generateTestApps(limit: number) {
  const appList: MarketplaceApp[] = [
    {
      author: 'Zoom',
      title: 'Zoom VMR',
      imgSrc: 'Zoom',
      id: 'zoom'
    },
    {
      author: 'TeamViewer AG',
      title: 'TeamViewer',
      imgSrc: 'TeamViewer',
      id: 'tv'
    },
    {
      author: 'Microsoft',
      title: 'Microsoft Teams',
      imgSrc: 'Teams',
      id: 'teams'
    },
    {
      author: 'Motorola Solutions',
      title: 'Wave PTT',
      imgSrc: 'Motorola',
      id: 'moto'
    }
  ];

  const allApps = [
    ...appList,
    ...appList,
    ...appList,
    ...appList,
    ...appList,
    ...appList,
    ...appList,
    ...appList,
    ...appList,
    ...appList
  ].slice(0, limit);

  allApps.forEach((app) => {
    app.certified = !Math.round(Math.random());

    if (app.imgSrc === 'Zoom') {
      app.imgSrc = '/assets/sample/sample-app-zoom.webp';
    } else if (app.imgSrc === 'TeamViewer') {
      app.imgSrc = '/assets/sample/sample-app-teamviewer.webp';
    } else if (app.imgSrc === 'Teams') {
      app.imgSrc = '/assets/sample/sample-app-teams.webp';
    } else if (app.imgSrc === 'Motorola') {
      app.imgSrc = '/assets/sample/sample-app-motorola.webp';
    }
  });

  return allApps;
}

export function shuffle<T>(array: Array<T>): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
