import { interval, map, Observable, takeWhile, filter, startWith } from 'rxjs';
import { PopupNotificationConfig } from './popup-notification-config';

type RemoveHandler = (value: PopupNotificationRef) => void;

const DEFAULT_DURATION = 5000;
const DEFAULT_UNDO_DURATION = 12000;
const DEFAULT_LOADING_DURATION = 20000;

export class PopupNotificationRef {
  private timeoutRef?: ReturnType<typeof setTimeout>;

  private _timeoutActive = false;

  private _startTime?: number;

  readonly countdown$: Observable<number>;

  constructor(private _id: string, private _config: PopupNotificationConfig, private _removeHandler: RemoveHandler) {
    this.resetDuration();

    this.countdown$ = interval(100).pipe(
      takeWhile(() => this._timeoutActive),
      map(() => {
        const difference = Date.now().valueOf() - (this._startTime || 0);

        const duration = this.config.duration || 0;

        const remaining = duration - difference;

        return (remaining / duration) * 100;
      }),
      filter((j) => j > 0 && j <= 100),
      startWith(100)
    );
  }

  /**
   * Unique ID of the Notification
   */
  get id(): string {
    return this._id;
  }

  get infinite(): boolean {
    return this.config.duration === -1;
  }

  get config(): PopupNotificationConfig {
    return this._config;
  }

  remove() {
    this._removeHandler(this);
  }

  updateConfig(newConfig: PopupNotificationConfig, keepDuration?: boolean) {
    const oldState = this._config.state;

    this._config.state = newConfig.state;
    this._config.text = newConfig.text;
    this._config.undoFunction = newConfig.undoFunction;

    let newDuration = newConfig.duration;

    // If the state has changed
    if (oldState !== newConfig.state && !newConfig.duration) {
      newDuration = fetchDefaultDuration(newConfig.state || 'info', !!this._config.undoFunction);
    }

    if (!keepDuration) {
      this.resetDuration(newDuration);
    }
  }

  resetDuration(newDuration?: number) {
    if (newDuration) {
      this._config.duration = newDuration;
    }

    this._config.duration =
      this._config.duration || fetchDefaultDuration(this._config.state || 'info', !!this._config.undoFunction);

    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
      this.timeoutRef = undefined;
      this._timeoutActive = false;
    }

    // Inifinite
    if (this._config.duration === -1) {
      return;
    }

    this._startTime = Date.now().valueOf();

    this._timeoutActive = true;

    this.timeoutRef = setTimeout(() => {
      this._removeHandler(this);
    }, this.config.duration);
  }
}

function fetchDefaultDuration(state: string, hasUndo: boolean): number {
  if (hasUndo) {
    return DEFAULT_UNDO_DURATION;
  }

  return state === 'loading' ? DEFAULT_LOADING_DURATION : DEFAULT_DURATION;
}
