import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterColumnsComponent } from './rwc-table/filter-columns/filter-columns.component';
import { RwcTableComponent } from './rwc-table/rwc-table.component';

import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { SharedUtilsPipesModule } from '@realwear-cloud/shared/utils-pipes';
import { MaterialModule } from '../../material/material.module';
import { RwcTableColumnsModule } from './rwc-table/column-components/rwc-columns.module';
import { RwcTableFilterModule } from './filter/rwc-table-filter.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    CdkTableModule,
    SharedUtilsPipesModule,
    RwcTableColumnsModule,
    MatTooltipModule,
    RwcTableFilterModule
  ],
  declarations: [RwcTableComponent, FilterColumnsComponent],
  providers: [DatePipe],
  exports: [RwcTableComponent]
})
export class RwcTableModule {}
