import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { firstValueFrom, map, Observable } from 'rxjs';

@Injectable()
export class AuthService {
  readonly isAuthenticated$: Observable<boolean>;
  public isOperator$ = this.authService.userData$.pipe(map((userData) => userData && userData['role'] === 'operator'));

  constructor(
    public authService: OidcSecurityService,
    private events: PublicEventsService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isAuthenticated$ = authService.isAuthenticated$;
  }

  onInit() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.events.registerForEvents().subscribe(() => {
      // DO NOT REMOVE
    });
  }

  /* although this methode seems be ununsed, on runtime in AppMarketplace, the MarketPlaceAuth is
   ** injected as AuthService like so: {
   **       provide: MarketplaceAuth,
   **       useExisting: AuthService
   **     },
   **, and then authorizeForAppId is called
   */

  authorizeForAppId(appId: string) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    sessionStorage.setItem('redirect_app_id', appId);

    this.authService.authorize();
  }

  silentAuthorizeForAppId(appId: string) {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    sessionStorage.setItem('redirect_app_id', appId);

    this.authService.authorize({
      customParams: {
        prompt: 'none'
      }
    });
  }

  async logout() {
    // Should only logout if the user isn't authenticated
    const isAuthenticated = await firstValueFrom(this.authService.isAuthenticated$);

    if (!isAuthenticated) {
      return;
    }

    this.authService.logoff();
  }
}
