import { Component, Input } from '@angular/core';

import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'realwear-cloud-nothing-here',
  templateUrl: './nothing-here.component.html',
  styleUrls: ['./nothing-here.component.scss']
})
export class NothingHereComponent {
  public readonly faInfoCircle = faInfoCircle;
  @Input() message = '';
  @Input() isEnterprise = false;
}
